import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  showDesktop = signal<boolean>(true);
  showMobile = signal<boolean>(true);
  showBackBtn = signal<boolean>(false);

  titleMobile = signal<string>('');

  height: number = 0;

  constructor() {
  }

  setTitleMobile(title: string | undefined) {
      if (title) {
          this.titleMobile.set(title);
      } else {
          // clear
          this.titleMobile.set('');
      }
  }

  getHeight() {
      return this.height;
  }

  setHeight(h: number) {
      this.height = h;
  }
}
