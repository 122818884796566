import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavService {

    selected = signal('community');

    constructor(
    ) { }

    setSelectedPage(page: string): void {
        this.selected.set(page);
    }

}
