<div class="modal">

    <div class="modal-content {{ size }} bg-white {{ customClass }}">
        <div class="head">
            <div>
                <span class="title" *ngIf="title">{{ title }}</span>
            </div>
            <div *ngIf="canClose" class="close" (click)="close()">
                <i class="bi bi-x-lg"></i>
            </div>
        </div>
        <ng-content></ng-content>
    </div>

</div>
