import { Component, OnInit, AfterViewInit, ViewChild, effect, Inject, PLATFORM_ID, signal, ElementRef, afterNextRender } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

import { AccountUser } from '../../_models/user';

import { AccountService } from '../../_services/account.service';
import { FooterService } from '../../_services/footer.service';
import { HeaderService } from '../../_services/header.service';
import { NavService } from '../../_services/nav.service';
import { NetworkService } from '../../_services/network.service';

@Component({
  selector: 'app-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss']
})
export class FooterMobileComponent implements OnInit, AfterViewInit {

    @ViewChild('footerContainer') footerContainer: ElementRef;
    @ViewChild('postModal') postModal;
    @ViewChild('postThanksModal') postThanksModal;
    @ViewChild('shareProfileModule') shareProfileModule;

    accountUser: AccountUser | undefined | null;

    notificationUnreadCount: number | null = 0;

    selectedPage: string = 'home';
    selected: string = 'community';

    isIOS: boolean = false;
    isNativePlatform: boolean = false;
    isKeyboardVisible: boolean = true;

    hiOpen = signal<boolean>(true);

    profileMenuOpen: boolean = false;

    showMobile: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private accountService: AccountService,
        private footerService: FooterService,
        private headerService: HeaderService,
        private navService: NavService,
        private networkService: NetworkService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        effect(() => {
            this.accountUser = this.accountService.loggedInAccountUser();
            this.selected = this.navService.selected();
            this.getNotificationUnreadCount();

            this.showMobile = this.footerService.showMobile();
        });

      afterNextRender(() => {
        this.route.paramMap.subscribe(params => {
          if (params) {
            const path = window.location.pathname;
            let page = 'none';

            if (path.startsWith('/company/')) {
              page = 'companies';

            } else if (path.startsWith('/community')) {
              page = 'hubs';

            } else if (path.startsWith('/hubs') || path.includes('/hub/')) {
              page = 'hubs';

            } else if (path.startsWith('/companies')) {
              page = 'companies';

            } else if (path.startsWith('/jobs') || path.includes('/job/')) {
              page = 'jobs';

            } else if (path.startsWith('/referral')) {
              page = 'referral';

            } else if (path.startsWith('/knowledge')) {
              page = 'stories';

            } else if (path.startsWith('/people')) {
              page = 'people';

            } else if (path.startsWith('/dc/staff')) {
              page = 'staff';

            } else if (path.startsWith('/me/notifications')) {
              page = 'notifications';

            } else if (path.startsWith('/hi/')) {
              page = 'profile';

            } else if (path == '/') {
              page = 'career';

            } else {
              page = 'none';
            }

            this.navService.selected.set(page);

          }
        });
      });
    }

    ngOnInit(): void {
        /*this.navService.updateSelectedPage();
        this.navService.selectedPage.subscribe(
            page => {
                this.selectedPage = page;
            }
        );*/

        if (isPlatformBrowser(this.platformId)) {
            if (Capacitor.getPlatform() == 'ios') {
                this.isIOS = true;
            }
            if (Capacitor.isNativePlatform()) {
                this.isNativePlatform = true;
            }

            if (Capacitor.isNativePlatform()) {
                Keyboard.addListener('keyboardWillShow', info => {
                    //console.log('keyboard will show with height:', info.keyboardHeight);
                    this.isKeyboardVisible = true;
                });

                /*Keyboard.addListener('keyboardDidShow', info => {
                    //console.log('keyboard did show with height:', info.keyboardHeight);
                    this.isKeyboardVisible = false;
                });*/

                Keyboard.addListener('keyboardWillHide', () => {
                    //console.log('keyboard will hide');
                    this.isKeyboardVisible = false;
                });

                /*Keyboard.addListener('keyboardDidHide', () => {
                    //console.log('keyboard did show with height:', info.keyboardHeight);
                    this.isKeyboardVisible = true;
                });*/
            }
        }

        // On NavigationEnd
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.footerService.setHeight(this.footerContainer.nativeElement.offsetHeight);
            }
        });
    }

    ngAfterViewInit() {
    }

    toggleProfileMenu() {
        this.profileMenuOpen = !this.profileMenuOpen;
    }

    getNotificationUnreadCount(): void {
        this.networkService.getNotificationUnreadCount().subscribe(
            count => {
                this.notificationUnreadCount = count;
            }
        );
    }

    selectPage(page: string) {
        this.navService.selected.set(page);
        this.selected = page;
    }

    post() {
        if (this.accountUser) {
            this.postModal.open();
        } else {
            this.router.navigate(['/signup']);
        }
    }

    logout() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('viewingProfileSlug');
        }
        console.log('logout');
        this.accountService.setLoggedInAccountUser(null);
        this.router.navigate(['/']);
    }

}
