<div class="mt-2 mb-5">
    <h2 class="text-xl font-medium">Share profile</h2>
</div>

<div class="grid grid-cols-2 md:grid-cols-3 gap-2 my-6">
    <button class="btn-outline-blue-sm" (click)="shareOnLinkedIn()"><i class="bi bi-linkedin"></i> LinkedIn</button>
    <button class="btn-outline-blue-sm" (click)="shareOnFacebook()"><i class="bi bi-facebook"></i> Facebook</button>
    <button class="btn-outline-blue-sm" (click)="shareOnTwitter()"><i class="bi bi-twitter"></i> X</button>
    <button class="btn-outline-blue-sm" (click)="shareOnMessenger()"><i class="bi bi-messenger"></i> Messenger</button>
    <button class="btn-outline-blue-sm" (click)="shareOnWhatsApp()"><i class="bi bi-whatsapp"></i> WhatsApp</button>
    <button class="btn-outline-blue-sm" (click)="copyText()">URL</button>
</div>

<div *ngIf="profileAccountUser()" class="flex justify-center">
    <qrcode [qrdata]="'https://hicareer.com/hi/' + profileAccountUser()?.getSlug()" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
</div>

<div (click)="copyText()" class="cursor-copy text-center" *ngIf="profileAccountUser()">
    <div class="text-sm text-gray-500">Copy to clipboard</div>
    <div class="text-base font-medium">hicareer.com/hi/{{ profileAccountUser()?.getSlug() }}</div>
</div>

