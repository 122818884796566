import { environment } from '../../environments/environment';

import { Injectable, signal, inject, effect } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountUser, AccountUserProfile, AccountUserPublic, AccountUserSkillSet } from '../_models/user';
import { AccountIndustry, Invite } from '../_models/account';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

    http = inject(HttpClient);

    domain = environment.domain;
    prefix = environment.api_prefix;

    /*private accountUserSubject = new BehaviorSubject<AccountUser | undefined | null>(null);
    loggedInAccountUser = this.accountUserSubject.asObservable();
    */

    loggedInAccountUserSig = signal<AccountUser | undefined | null>(undefined);
    readonly loggedInAccountUser = this.loggedInAccountUserSig.asReadonly();

    pushNotificationsAllowedSig = signal<boolean>(true); // assume true by default, updated otherwise

    clientIpAddress = signal<string>('');

    constructor(
        //private http: HttpClient,
        //private userService: UserService
    ) {
        // the service handles getting the account user
        /*const au = this.getLoggedInAccountUser();
        if (au == null) {
            //this.getUser();
            this.getAccountUserObject();
        }*/
    }

    isLoggedIn() {
        return this.loggedInAccountUserSig() != undefined && this.loggedInAccountUserSig() != null;
    }

    isNotLoggedIn() {
        return this.loggedInAccountUserSig() == undefined || this.loggedInAccountUserSig() == null;
    }

    getLoggedInAccountUser(): Observable<AccountUser | undefined | null> {
        return of(this.loggedInAccountUserSig());
    }

    getCurrentAccountUser(): Observable<AccountUser> {
        const url = `${this.domain}${this.prefix}/account-user/logged_in/?s=${new Date().getTime()}`;
        return this.http.get<AccountUser>(url).pipe(
            map(account_user => new AccountUser().deserialize(account_user))
        );
    }

    getProfileCompleted(): Observable<number | null> {
        const url = `${this.domain}${this.prefix}/account-user/profile_completed/?s=${new Date().getTime()}`;
        return this.http.get<number | null>(url);
    }

    updateAccountUserAppToken(token: string): Observable<any> {
        const url = `${this.domain}${this.prefix}/account-user/update_app_token/`;
        return this.http.post<any>(url, {app_token: token});
    }

    /*getAccountUserObject() {
        const storage = JSON.parse(localStorage.getItem('currentUser') ?? '{}');
        if (storage != null && storage != undefined && storage != '{}' && storage['au_id'] != null && storage['au_id'] != undefined && storage['au_id'] != '') {
            const lsu = storage; //['user'];

            // get the logged in AccountUser
            this.getAccountUser(storage['au_id']).subscribe(
                au => {
                    // add it to local storage
                    localStorage.setItem('currentAccountUser', JSON.stringify(au));

                    // add it to service for easy access
                    this.setLoggedInAccountUser(au);
                }
            );
            return lsu;
        } else {
            // remove user from local storage
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentAccountUser');
        }

    }*/

    setLoggedInAccountUser(accountUser: AccountUser | undefined | null) {
        //this.accountUserSubject.next(accountUser);
        this.loggedInAccountUserSig.set(accountUser);
        //this.loggedInAccountUser = of(accountUser);
    }

    /*getLoggedInAccountUser(): Observable<AccountUser> | undefined {
        const sAu = JSON.parse(localStorage.getItem('currentAccountUser') ?? '{}');
        if (sAu != null && sAu != undefined && sAu != '{}') {
            let au = new AccountUser().deserialize(sAu);
            this.setLoggedInAccountUser(au);
            return of(au);
        }

        this.getAccountUserObject(); //infinit loop?

        return undefined;
    }*/

    reloadLoggedInAccountUser() {
        this.getCurrentAccountUser().subscribe(
            au => {
                this.setLoggedInAccountUser(au);
            }
        );
    }


    /*isLoggedIn = new Observable((observer) => {

        this.loggedInAccountUser.subscribe(
            au => {
                if (au != undefined) {
                    observer.next(true);
                } else {
                    observer.next(false);
                }
            },
            error => {
                observer.next(false);
            }
        );

        //if (localStorage.getItem('currentUser')) {
        //    observer.next(true);
        //} else {
        //    observer.next(false);
        //}
        return {unsubscribe() {}};
    });*/

    // Public

    getSearchPublicAccountUsers(keywords: string, filter: any, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/account-user-public/search/'+ `?timestamp=${timestamp}` + '&page=' + page;

        let data = {
            paginate: 8,
            keywords: keywords,
        };

        // add data type key and data points to filter
        for (let key in filter) {
            data['filter_' + key] = filter[key];
        }

        return this.http.post<any>(url, data);
    }

    getAccountPublicInfo(hostname: string): Observable<any> {
        let url = this.domain + this.prefix + '/account-public/?hostname=' + hostname;
        return this.http.get(url);
    }

    // Get the current logged in user data
    /*getLoggedInUser(): Observable<User> {
        let url = this.domain + this.prefix + '/current-user/';
        //return this.http.get<User>(url);
        return this.http.get<User>(url).pipe(
            map(user => new User().deserialize(user))
        );
    }

    // Get the current logged in users AccountUser
    getLoggedInAccountUser(): Observable<AccountUser> {
        let url = this.domain + this.prefix + '/current-user-accountuser';
        //return this.http.get<AccountUser>(url);
        return this.http.get<AccountUser[]>(url).pipe(
            map(account_user => new AccountUser().deserialize(account_user))
        );
    }*/

    // List of AccountUser objects associated with the logged in user
    getLoggedInUserAccountUsers(): Observable<AccountUser[]> {
        let url = this.domain + this.prefix + '/current-user-accountusers';
        //return this.http.get<AccountUser[]>(url);
        return this.http.get<AccountUser[]>(url).pipe(
            map(account_users => account_users
                .map(account_user => new AccountUser().deserialize(account_user))
            )
        );
    }

    // List of AccountUser objects for the account that the user is logged in to
    getAllAccountUsers(role?: string, search?: string): Observable<AccountUser[]> {
        let url = this.domain + this.prefix + '/account-user-small';

        if (role) {
            url = url + '/?role=' + role;
            if(search) {
                url = url + '&search=' + search;
            }
        } else {
            if(search) {
                url = url + '?search=' + search;
            }
        }

        return this.http.get<AccountUser[]>(url).pipe(
            map(account_users => account_users
                .map(account_user => new AccountUser().deserialize(account_user))
            )
        );
    }

    // use this to replace getAllAccountUsers in the future...
    getAccountUsersSmall(role?: string, search?: string) {
        return this.getAllAccountUsers(role, search);
    }

    getAccountUserSmall(id: number): Observable<AccountUser> {
        let url = this.domain + this.prefix + '/account-user-small/' + id + '/';
        return this.http.get<AccountUser>(url).pipe(
            map(account_user => new AccountUser().deserialize(account_user))
        );
    }

    // AccountUser

    getAccountUsers(): Observable<AccountUser[]> {
        let url = this.domain + this.prefix + '/account-user/';
        return this.http.get<AccountUser[]>(url).pipe(
            map(objs => objs
                .map(obj => new AccountUser().deserialize(obj))
            )
        );
    }

    getAccountUser(id: number): Observable<AccountUser> {
        const salt = (new Date()).getTime(); // avoid browser cache
        let url = this.domain + this.prefix + '/account-user/' + id + '/';
        url += '?s=' + salt;
        return this.http.get<AccountUser>(url).pipe(
            map(account_user => new AccountUser().deserialize(account_user))
        );
    }

    checkIfAccountUserExists(id: number): Observable<any> {
        return this.http.post(this.domain + this.prefix + '/account-user/' + id + '/exists/', {});
    }

    updateAccountUser(data): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/account-user/' + data.id + '/', data);
    }

    searchAccountUsers(keywords: string): Observable<AccountUser[]> {
        let url = this.domain + this.prefix + '/account-user/search/?keywords=' + encodeURI(keywords);
        return this.http.get<AccountUser[]>(url).pipe(
            map(objs => objs
                .map(obj => new AccountUser().deserialize(obj))
            )
        );
    }

    // Public profile

    // used in DCP
    getAccountUserPublicProfile(hash): Observable<any> {
        let url = this.domain + this.prefix + '/account-user-public-profile/' + hash + '/';
        return this.http.get<AccountUser>(url).pipe(
            map(account_user => new AccountUser().deserialize(account_user))
        );
    }

    // used in front
    //getAccountUserPublicProfiles(filter, skillsLogic = 'and'): Observable<AccountUserPublic[]> {
    getAccountUserPublicProfiles(searchText: string): Observable<AccountUserPublic[]> {
        //let url = this.domain + this.prefix + '/account-user-public/?keywords='+filter['keywords']+'&role='+filter['role']+'&skills='+filter['skills']+'&titles='+filter['titles']+'&cities='+filter['cities']+'&skillsLogic='+skillsLogic;
        let url = this.domain + this.prefix + '/account-user-public/';

        if (searchText) {
            url = url + '?keywords=' + encodeURIComponent(searchText);
        }

        return this.http.get<AccountUserPublic[]>(url).pipe(
            map(profiles => profiles
                .map(profile => new AccountUserPublic().deserialize(profile))
            )
        );
    }

    // Skill Set
    getAccountUserSkillSets(skillName: string): Observable<AccountUserSkillSet[]> {
        let url = this.domain + this.prefix + '/account-user-skill-set/?skillName='+encodeURIComponent(skillName);

        return this.http.get<AccountUserSkillSet[]>(url).pipe(
            map(ss => ss
                .map(s => new AccountUserSkillSet().deserialize(s))
            )
        );
    }

    // used in front
    getAccountUserPublic(accountUserId: number): Observable<AccountUserPublic> {
        const salt = (new Date()).getTime(); // avoid browser cache
        let url = this.domain + this.prefix + '/account-user-public/' + accountUserId + '/';
        url += '?s=' + salt;
        return this.http.get<AccountUserPublic>(url).pipe(
            map(account_user => new AccountUserPublic().deserialize(account_user))
        );
    }

    getAccountUserPublicBySlug(slug: string): Observable<AccountUserPublic> {
        const salt = (new Date()).getTime(); // avoid browser cache
        let url = this.domain + this.prefix + '/account-user-public/' + slug + '/?type=slug&s=' + salt;

        return this.http.get<AccountUserPublic>(url).pipe(
            map(account_user => new AccountUserPublic().deserialize(account_user))
        );
    }

    getAccountUserPublicPopup(slug: string): Observable<AccountUserPublic> {
        const salt = (new Date()).getTime(); // avoid browser cache
        let url = this.domain + this.prefix + '/account-user-public/' + slug + '/popup/?s=' + salt;

        return this.http.get<AccountUserPublic>(url).pipe(
            map(account_user => new AccountUserPublic().deserialize(account_user))
        );
    }

    updateAccountUserPublic(data, auId: number): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/account-user-public/' + auId + '/', data)
    }

    uploadAccountUserCv(data, auId: number): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/account-user-public/' + auId + '/upload_cv/', data)
    }

    promoteToConsultantAccountUserPublic(data): Observable<any> {
        return this.http.post(this.domain + this.prefix + '/account-user-public/' + data.id + '/promote_to_consultant/', data)
    }

    checkInviteAsTalentAgentAccountUserPublic(): Observable<any> {
        return this.http.post(this.domain + this.prefix + '/account-user-public/0/check_invite_as_talent_agent/', {})
    }

    acceptInviteAsTalentAgentAccountUserPublic(): Observable<any> {
        return this.http.post(this.domain + this.prefix + '/account-user-public/0/accept_invite_as_talent_agent/', {})
    }

    deleteAccountUser(accountUserId: number): Observable<any> {
        return this.http.delete(this.domain + this.prefix + '/account-user/' + accountUserId + '/');
    }


    // Profile

    getAccountUserProfiles(): Observable<AccountUserProfile[]> {
        let url = this.domain + this.prefix + '/account-user-profile/';
        return this.http.get<AccountUserProfile[]>(url).pipe(
            map(profiles => profiles
                .map(profile => new AccountUserProfile().deserialize(profile))
            )
        );
    }

    // NOTE the we use AccountUser here instead of AccountUserProfile to access
    // AccountUser functions but the serializer is only returning the profile (cv and hash)
    // of the AccountUser object its safe to display
    getAccountUserProfile(id: number): Observable<any> {
        let url = this.domain + this.prefix + '/account-user-profile/' + id + '/';
        return this.http.get<AccountUser>(url).pipe(
            map(p => new AccountUser().deserialize(p))
        );
    }

    updateAccountUserProfile(data, id): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/account-user/' + id + '/update_profile/', data)
    }

    // AccountIndustry

    getAccountIndustries(): Observable<AccountIndustry[]> {
        let url = this.domain + this.prefix + '/account-industry/';
        return this.http.get<AccountIndustry[]>(url).pipe(
            map(objs => objs
                .map(obj => new AccountIndustry().deserialize(obj))
            )
        );
    }

    getAccountIndustry(id: number): Observable<AccountIndustry> {
        let url = this.domain + this.prefix + '/account-industry/' + id + '/';
        return this.http.get<AccountIndustry>(url).pipe(
            map(obj => new AccountIndustry().deserialize(obj))
        );
    }

    // password reset

    requestPasswordReset(email): Observable<any> {
        return this.http.post(this.domain + '/api/password-reset/', { 'email': email })
    }

    doPasswordReset(token, password): Observable<any> {
        return this.http.post(this.domain + '/api/password-reset/confirm/', { 'password': password, 'token': token })
    }

    // Invites
    getInvites(accountUserId): Observable<Invite[]> {
        const timestamp = Date.now();
        let url = this.domain + this.prefix + '/invite/' + `?timestamp=${timestamp}`;
        if (accountUserId) {
            url = url + '?from-au=' + accountUserId;
        }

        return this.http.get<Invite[]>(url).pipe(
            map(invites => invites
                .map(invite => new Invite().deserialize(invite))
            )
        );
    }

    getInvitesCompany(companyId): Observable<Invite[]> {
        let url = this.domain + this.prefix + '/invite/';
        if (companyId) {
            url = url + '?company=' + companyId;
        }

        return this.http.get<Invite[]>(url).pipe(
            map(invites => invites
                .map(invite => new Invite().deserialize(invite))
            )
        );
    }

    getReceivedInvites(accountUserId): Observable<Invite[]> {
        const timestamp = Date.now();
        let url = this.domain + this.prefix + '/invite/' + `?timestamp=${timestamp}`;
        if (accountUserId) {
            url = url + '&to-au=' + accountUserId;
        }

        return this.http.get<Invite[]>(url).pipe(
            map(invites => invites
                .map(invite => new Invite().deserialize(invite))
            )
        );
    }

    getInvite(id): Observable<Invite> {
        let url = this.domain + this.prefix + '/invite/' + id + '/';
        return this.http.get<Invite>(url).pipe(
            map(invite => new Invite().deserialize(invite))
        );
    }

    createInvite(data): Observable<any> {
        let url = this.domain + this.prefix + '/invite/';
        return this.http.post(url, data);
    }

    deleteInvite(id): Observable<any> {
        let url = this.domain + this.prefix + '/invite/' + id + '/';
        return this.http.delete(url);
    }

    acceptInvite(inviteId): Observable<any> {
        let url = this.domain + this.prefix + '/invite/' + inviteId + '/accept/';
        return this.http.post(url, {});
    }

    ignoreInvite(inviteId): Observable<any> {
        let url = this.domain + this.prefix + '/invite/' + inviteId + '/ignore/';
        return this.http.post(url, {});
    }


    // notification settings

    updateNotifyEmailHourlyUnread(period: string, type: string, accountUserId: number, value: boolean): Observable<any> {
        // type = 'messages' or 'notifications'
        let data = {
            'notification_name': 'notify_email_'+period+'_unread_'+type,
            'notification_value': value
        }
        return this.http.put(this.domain + this.prefix + '/account-user/' + accountUserId + '/update_notification/', data)
    }

    // Company Follow Requests, company requestst that a user follows them (connects)

    getCompanyFollowRequests(auId: number, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/account-user/' + auId + '/list_follow_requests/'+ `?timestamp=${timestamp}` + '&page=' + page;

        return this.http.get<any>(url);
        /*return this.http.get<CompanyFollowRequest[]>(url).pipe(
            map(requests => requests
                .map(request => new CompanyFollowRequest().deserialize(request))
            )
        );*/
    }

    acceptCompanyFollowRequest(CompanyFollowRequestId: number): Observable<any> {
        let url = this.domain + this.prefix + '/account-user/' + CompanyFollowRequestId + '/accept_follow_request/';
        return this.http.post<any>(url, {});
    }

    declineCompanyFollowRequest(CompanyFollowRequestId: number): Observable<any> {
        let url = this.domain + this.prefix + '/account-user/' + CompanyFollowRequestId + '/decline_follow_request/';
        return this.http.post<any>(url, {});
    }

    getFollowingCompanies(auId: number, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/account-user/' + auId + '/list_following/'+ `?timestamp=${timestamp}` + '&page=' + page;

        let data = {
            paginate: 8,
        };

        return this.http.post<any>(url, data);
    }


    getClientIpAddress(): Observable<string> {
        if (this.clientIpAddress() == '') {
            return this.http.get<{ ip: string }>('https://api.ipify.org/?format=json')
            .pipe(
                //map(response => response.ip),
                map(response => {
                    this.clientIpAddress.set(response.ip);
                    return response.ip;
                })
            );
        } else {
          return of(this.clientIpAddress());
        }
    }
}
