import { Component, Inject, PLATFORM_ID, input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';

import { AlertService } from '../../_services/alert.service';

import { AccountUser, AccountUserPublic } from '../../_models/user';

@Component({
  selector: 'app-profile-share',
  standalone: true,
  imports: [
      CommonModule,
      QRCodeModule,
  ],
  templateUrl: './profile-share.component.html',
  styleUrl: './profile-share.component.scss'
})
export class ProfileShareComponent {

    profileAccountUser = input.required<AccountUser | AccountUserPublic | null | undefined>();

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private alertService: AlertService,
    ) {

    }

    copyText() {
        //this.copyBtnText = "Copied!";

        navigator.clipboard.writeText('https://hicareer.com/hi/' + this.profileAccountUser()?.getSlug()).then().catch(e => console.error(e));

        //setTimeout( () => {
        //    this.copyBtnText = "Copy";
        //}, 3000 );

        // show alert
        this.alertService.success("Copied to clipboard");
    }

    shareOnTwitter() {
        const txt = "Check out this profile on HiCareer: ";
        const profileUrl = 'https://hicareer.com/hi/' + this.profileAccountUser()?.getSlug();
        const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(txt)}&url=${encodeURIComponent(profileUrl)}`;

        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
    }

    shareOnLinkedIn() {
        const profileUrl = 'https://hicareer.com/hi/' + this.profileAccountUser()?.getSlug();
        const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(profileUrl)}`;

        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
    }

    shareOnFacebook() {
        const profileUrl = 'https://hicareer.com/hi/' + this.profileAccountUser()?.getSlug();
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(profileUrl)}`;

        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
    }

    shareOnMessenger() {
        const profileUrl = 'https://hicareer.com/hi/' + this.profileAccountUser()?.getSlug();
        let urlToShare = encodeURIComponent(profileUrl);
        if (isPlatformBrowser(this.platformId)) {
            window.open(`https://www.facebook.com/dialog/send?app_id=652449142424642&link=${urlToShare}&redirect_uri=${urlToShare}`, "_blank");
        }
    }

    shareOnWhatsApp() {
        const profileUrl = 'https://hicareer.com/hi/' + this.profileAccountUser()?.getSlug();
        var message = encodeURIComponent("Check out this post: " + profileUrl);
        if (isPlatformBrowser(this.platformId)) {
            window.open(`https://wa.me/?text=${message}`, "_blank");
        }
    }


}
