export const environment = {
    production: true,
    env: 'production',
    domainFrontend: 'https://hicareer.com',
    domain: 'https://api.hicareer.com',
    api_prefix: '/api/v1',
    chat_server: 'https://cs.hicareer.com'
};

// NOTE stripePriceId is not the same as in staging or development

export const businessPlans = {
    'sv': [
        {
            'annually': [
                { name: 'Business Premium 30', type: 'bp30', period: 'annually', stripePriceId: 'price_1L84h3H4kQOk6NUAVftvwI9n', pricePerMonth: 1590, pricePerYear: 1590*12, currency: 'SEK', discount: 11, selected: true },
                { name: 'Business Premium 100', type: 'bp100', period: 'annually', stripePriceId: 'price_1L84noH4kQOk6NUAo4kW4iBL', pricePerMonth: 2690, pricePerYear: 2690*12, currency: 'SEK', discount: 10, selected: true },
                { name: 'Business Premium 200', type: 'bp200', period: 'annually', stripePriceId: 'price_1L84sLH4kQOk6NUAGmJyrlPr', pricePerMonth: 4490, pricePerYear: 4490*12, currency: 'SEK', discount: 10, selected: true },
            ],
            'monthly': [
                { name: 'Business Premium 30', type: 'bp30', period: 'monthly', stripePriceId: 'price_1L84h3H4kQOk6NUAQs6imjND', pricePerMonth: 1790, pricePerYear: 1790*12, currency: 'SEK', discount: 0, selected: true },
                { name: 'Business Premium 100', type: 'bp100', period: 'monthly', stripePriceId: 'price_1L84ohH4kQOk6NUASOJhSita', pricePerMonth: 2990, pricePerYear: 2990*12, currency: 'SEK', discount: 0, selected: true },
                { name: 'Business Premium 200', type: 'bp200', period: 'monthly', stripePriceId: 'price_1L84t5H4kQOk6NUAaa3THtAh', pricePerMonth: 4990, pricePerYear: 4990*12, currency: 'SEK', discount: 0, selected: true },
            ]
        }
    ],
    'en': [
        {
            'annually': [
                { name: 'Business Premium 30', type: 'bp30', period: 'annually', stripePriceId: 'price_1L84h3H4kQOk6NUACTmGQs3C', pricePerMonth: 159, pricePerYear: 159*12, currency: 'USD', discount: 11, selected: false },
                { name: 'Business Premium 100', type: 'bp100', period: 'annually', stripePriceId: 'price_1L84ppH4kQOk6NUAgS3IPtJB', pricePerMonth: 269, pricePerYear: 269*12, currency: 'USD', discount: 10, selected: false },
                { name: 'Business Premium 200', type: 'bp200', period: 'annually', stripePriceId: 'price_1L84thH4kQOk6NUASlBU0aQA', pricePerMonth: 449, pricePerYear: 449*12, currency: 'USD', discount: 10, selected: false },
            ],
            'monthly': [
                { name: 'Business Premium 30', type: 'bp30', period: 'monthly', stripePriceId: 'price_1L84h3H4kQOk6NUA7jxHeO4l', pricePerMonth: 179, pricePerYear: 179*12, currency: 'USD', discount: 0, selected: false },
                { name: 'Business Premium 100', type: 'bp100', period: 'monthly', stripePriceId: 'price_1L84qLH4kQOk6NUAA9Riznt5', pricePerMonth: 299, pricePerYear: 299*12, currency: 'USD', discount: 0, selected: false },
                { name: 'Business Premium 200', type: 'bp200', period: 'monthly', stripePriceId: 'price_1L84uQH4kQOk6NUARpt0KNgl', pricePerMonth: 499, pricePerYear: 499*12, currency: 'USD', discount: 0, selected: false },
            ]
        }
    ]
}

