import { Deserializable } from "./deserializable";


export class AccountUserGhost implements Deserializable {
    id: number;
    related_data_points: any;
    status: string;
    is_premium: boolean;

    dpStatus: any = [];
    dpBasicLanguage: any = [];
    dpExperienceSkills: any = [];
    dpExperienceTechStack: any = [];
    dpExperienceTools: any = [];

    deserialize(input: any) {
        Object.assign(this, input);

        /*this.dpStatus = this.getDataPoints('basic_status');
        this.dpBasicLanguage = this.getDataPoints('basic_language');
        this.dpExperienceSkills = this.getDataPoints('experience_skillset');
        this.dpExperienceTechStack = this.getDataPoints('experience_tech_stack');
        this.dpExperienceTools = this.getDataPoints('experience_tools');
*/
        return this;
    }

    getId() {
        return this.id;
    }

    getStatus() {
        return this.status;
    }
    isPremium() {
        return this.is_premium;
    }

    getRelatedDataPoints() {
        let l: RelatedDataPoint[] = [];
        if (this.related_data_points) {
            for (let i of this.related_data_points) {
                l.push(new RelatedDataPoint().deserialize(i));
            }
        }
        return l;
    }

    getDataPointsByKey(key: string) {
        let l: (DataPoint | null)[] = [];
        if (this.related_data_points) {
            for (let i of this.getRelatedDataPoints()) {
                if (i.getDataTypeKey() == key) {
                    l.push(i.getDataPoint());
                }
            }
        }
        return l;
    }

    // NOTE not in use but can replace getDataPointsByKey to avoid keys
    /*getDataPointsById(id: number) {
        let l = [];
        if (this.related_data_points) {
            for (let i of this.getRelatedDataPoints()) {
                if (i.getDataTypeId() == id) {
                    l.push(i.getDataPoint());
                }
            }
        }
        return l;
    }*/

    getDataPoints(key: string, locale: string = 'en'): string[] {
        let l: any[] = [];
        let mock = {};
        let dp: DataPoint | null;

        if (this.related_data_points) {
            for (let rdp of this.getRelatedDataPoints()) {
                mock = {
                    'id': null,
                    'value': null,
                    'experience': null
                };
                if (rdp.getDataTypeKey() == key) {
                    dp = rdp.getDataPoint();
                    // create mock
                    if (dp) {
                        mock['id'] = dp.getId();
                        mock['value'] = dp.getValue(locale);
                        if (rdp.getExperience())
                            mock['experience'] = rdp.getExperience();

                        l.push(mock);
                    }
                }
            }
        }
        return l;
    }

    getDataPointStringList(key: string, locale: string = 'en', highlightDataPoints: number[] = []): string[] {
        let l: string[] = [];
        for (let dp of this.getDataPointsByKey(key)) {
            if (dp && dp.getDataTypeKeys().includes(key)) {
                const highlight = highlightDataPoints.find(dpId => dpId == dp?.getId());
                if (highlight)
                    l.push('<b>' + dp.getValue(locale) + '</b>');
                else
                    l.push(dp.getValue(locale));
            }
        }
        return l;
    }

    getDataPointsFirstString(key: string, locale: string = 'en') {
        let l = this.getDataPointStringList(key, locale);
        if (l.length > 0)
            return l[0];
        return '';
    }

    getDataPointsAsString(key: string, locale: string = 'en', highlightDataPoints: number[] = []) {
        let l = this.getDataPointStringList(key, locale, highlightDataPoints);
        if (l.length > 0)
            return l.join(', ');
        return '';
    }
}


export class DataType implements Deserializable {
    id: number;
    key: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    /*getValue(locale = 'en') {
        if (this.value) {
            if (locale != 'en' && !this.value[locale])
                return this.value['en']; // fall back to default
            return this.value[locale];
        }
        return null;
    }*/

    getKey() {
        return this.key;
    }
}

export class GroupDataType implements Deserializable {
    id: number;
    group: string;
    key: string;
    sub_key: string;
    order: number;
    can_add_data_point: boolean;
    single_select: boolean;
    duplicates_allowed: boolean;
    show_featured: boolean;
    show_related_sub_data_type: boolean;
    show_related_experience: boolean;
    show_related_start_date: boolean;
    show_related_end_date: boolean;
    show_view_type: string;
    required: boolean;
    visible: boolean;
    data_point_count: number;
    data_type_label: string;

    related_data_point_count: number;

    sort_by: string;
    limit_results: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getGroup() {
        return this.group;
    }

    getDataTypeKey() {
        return this.key;
    }

    getSubDataTypeKey() {
        return this.sub_key;
    }

    getOrder() {
        return this.order;
    }

    getCanAddDataPoint() {
        return this.can_add_data_point;
    }

    isSingleSelect() {
        return this.single_select;
    }

    isDuplicateAllowed() {
        return this.duplicates_allowed;
    }

    // TODO Fix connection with backend
    showFeatured() {
        return true;
    }

    showRelatedSubDataType() {
        return this.show_related_sub_data_type;
    }

    showRelatedExperience() {
        return this.show_related_experience;
    }

    showRelatedStartDate() {
        return this.show_related_start_date;
    }

    showRelatedEndDate() {
        return this.show_related_end_date;
    }

    showViewType() {
        return this.show_view_type;
    }

    isRequired() {
        return this.required;
    }

    isVisible() {
        return this.visible;
    }

    // TODO Fix connection with backend
    getSuggestionMinChars() {
        return 1;
    }

    getDataPointCount() {
        return this.data_point_count;
    }

    getDataTypeLabel() {
        return this.data_type_label;
    }

    // used to save the count of related data points returned in this object
    setRelatedDataPointCount(count: number) {
        this.related_data_point_count = count;
    }

    getRelatedDataPointCount() {
        return this.related_data_point_count;
    }

    getSortBy() {
        return this.sort_by;
    }

    getLimitResults() {
        return this.limit_results;
    }

}

export class DataPoint implements Deserializable {
    id: number;
    data_types: any;
    data_type: number;
    parent_data_point: any;
    value: any;
    featured_order: number;
    verified: boolean;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getDataTypes() {
        let l: DataType[] = [];
        if (this.data_types) {
            for (let i of this.data_types) {
                l.push(new DataType().deserialize(i));
            }
        }

        return l;
    }

    getDataTypeIds() {
        let l: number[] = [];
        if (this.data_types) {
            for (let i of this.data_types) {
                l.push(i['id']);
            }
        }
        return l;
    }

    getDataTypeKeys() {
        let l: string[] = [];
        if (this.data_types) {
            for (let i of this.data_types) {
                l.push(i['key']);
            }
        }
        return l;
    }

    // sent to serializer context via component
    getDataTypeId() {
        return this.data_type;
    }

    getParentDataPoint() {
        return this.parent_data_point;
    }

    getRawValue() {
        return this.value;
    }

    getValue(locale = 'en') {
        if (this.value) {
            if (locale != 'en' && !this.value[locale])
                return this.value['en']; // fall back to default
            return this.value[locale];
        }
        return '';
    }

    getValues() {
        let values: any[] = [];
        if (this.value) {
            for (let key in this.value) {
                values.push(this.value[key]);
            }
        }
        return values;
    }

    getValueOfKey() {
        if (this.value) {
            if (this.value['key'])
                return this.value['key'];
        }
        return '';
    }

    isFeatured() {
        return this.featured_order != undefined;
    }

    getFeaturedOrder() {
        return this.featured_order;
    }

    isVerified() {
        return this.verified;
    }

}

export class DataPointInfo implements Deserializable {
    id: number;
    data_point: DataPoint;
    data_type: number;
    language: string;
    type: string;
    type_definition: string;
    info: string;
    verified: boolean;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getDataPointId() {
        return this.data_point;
    }

    getDataTypeId() {
        return this.data_type;
    }

    getLanguage() {
        return this.language;
    }

    getType() {
        return this.type;
    }

    getTypeDefinition() {
        return this.type_definition;
    }

    getInfo() {
        return this.info;
    }

    isVerified() {
        return this.verified;
    }

}


export class RelatedDataPoint implements Deserializable {
    id: number;
    data_point: DataPoint;
    dp: DataPoint;

    data_type: number;

    data_type_key: string;
    dtk: string;

    data_type_label: string;

    sub_data_point: DataPoint;
    sub_data_type_key: string;
    sub_data_type_label: string;

    start_date: any;
    end_date: any;
    experience: number;

    // if the data point is a company, set its page slug here
    pageSlug: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getDataPoint() {
        let dp: DataPoint | null = null;

        if (this.data_point)
            dp = this.data_point;
        else if (this.dp)
            dp = this.dp;

        if (dp)
            return new DataPoint().deserialize(dp);
        return null;
    }

    getDataPointId() {
        return this.getDataPoint()?.getId();
    }

    getDataPointValue() {
        if (this.getDataPoint())
            return this.getDataPoint()?.getValue()
        return null;
    }

    getDataTypeId() {
        return this.data_type;
    }

    getDataTypeKey() {
        return this.data_type_key || this.dtk;
    }

    getDataTypeLabel() {
        return this.data_type_label;
    }

    getSubDataPoint() {
        return new DataPoint().deserialize(this.sub_data_point);
    }

    getSubDataTypeKey() {
        return this.sub_data_type_key;
    }

    getSubDataTypeLabel() {
        return this.sub_data_type_label;
    }

    getStartDate() {
        return this.start_date;
    }

    getEndDate() {
        return this.end_date;
    }

    getExperience() {
        return this.experience;
    }

}


export class RelatedDataPointJob extends RelatedDataPoint {

    override data_type_label: string;

    override getDataTypeLabel() {
        return this.data_type_label['job']['en']['viewing'];
    }

}


export class FeaturedDataPoint implements Deserializable {
    id: number;
    data_point: DataPoint;
    data_type: DataType;

    order: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getDataPoint() {
        return new DataPoint().deserialize(this.data_point);
    }

    getDataPointId() {
        if (this.data_point)
            return this.getDataPoint().getId()
        return null;
    }

    getDataPointValue() {
        if (this.data_point)
            return this.getDataPoint().getValue()
        return null;
    }

    getDataType() {
        return new DataType().deserialize(this.data_type);
    }

    getDataTypeKey() {
        if (this.data_type)
            return this.data_type['key'];
        return null;
    }

    getOrder() {
        return this.order;
    }

}
