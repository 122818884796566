import { Deserializable } from "./deserializable";

export class Image implements Deserializable {
    id: number;
    account: number;
    img: string;
    created: any;
    created_by: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getAccountId() {
        return this.account;
    }

    getImg() {
        return this.img;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }
}



