import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ModalModule } from '../modal/modal.module';
//import { PostSharedModule } from '../network/post/post-shared.module';

import { FooterDesktopComponent } from './footer-desktop/footer-desktop.component';
import { FooterMobileComponent } from './footer-mobile/footer-mobile.component';
import { ProfileShareComponent } from '../account-user/profile-share/profile-share.component';



@NgModule({
  declarations: [
    FooterDesktopComponent,
    FooterMobileComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ModalModule,
    //PostSharedModule,
    TranslateModule.forChild({
        extend: true
    }),
    ProfileShareComponent
  ],
  exports: [
    FooterDesktopComponent,
    FooterMobileComponent,
  ]
})
export class FooterModule { }
