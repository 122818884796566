import { RelatedDataPoint, GroupDataType, FeaturedDataPoint, DataPoint } from "./collection";
import { Deserializable } from "./deserializable";

import { Image } from './media';

//import * as DOMPurify from "isomorphic-dompurify";

export class Post implements Deserializable {
    id: number;
    parent: number;
    parent_slug: string;
    share: number;
    share_slug: string;
    user_name: string;
    user_title: string;
    user_company: string;
    user_image: string;
    user_slug: string;
    user_premium: boolean;
    user_recruiter: boolean;
    feed: number;
    hub_name: string;
    hub_slug: string;
    text: string;
    image: any;
    image1: string; // used in createPost
    status: string;
    slug: string;
    pinned: boolean;
    created: string;

    og_preview: boolean = true;
    og_title: string;
    og_description: string;
    og_image: string;
    og_url: string;
    og_type: string;
    og_locale: string;

    stats_impressions: number;
    stats_likes: number;
    stats_comments: number;
    stats_shares: number;
    stats_voted_up: number;
    stats_voted_down: number;
    stats_bookmarks: number;
    stats_engagements: number;
    stats_connections: number;
    stats_profile_visits: number;

    liked: boolean;
    can_delete: boolean;

    latest_comments: Post[];

    rdps: any;
    rdpsObj: RelatedDataPoint[];
    //rdpsObjExPostTopics: RelatedDataPoint[];

    deserialize(input: any) {
        Object.assign(this, input);
        if (this.rdps) {
            this.rdpsObj = this.rdps.map(rdp => new RelatedDataPoint().deserialize(rdp));
            //this.rdpsObjExPostTopics = this.rdpsObj.filter(rdp => rdp.data_type_key != 'post_topic');
        }

        return this;
    }

    getId() {
        return this.id;
    }

    getParentId() {
        return this.parent;
    }

    getParentSlug() {
        return this.parent_slug;
    }

    getShareId() {
        return this.share;
    }

    getShareSlug() {
        return this.share_slug;
    }

    getUserName() {
        return this.user_name;
    }

    getUserTitle() {
        return this.user_title;
    }

    getUserCompany() {
        return this.user_company;
    }

    getUserImage() {
        if (this.user_image) {
            return new Image().deserialize(this.user_image);
        }
        return new Image();
    }

    getUserSlug() {
        return this.user_slug;
    }

    isUserPremium() {
        return this.user_premium;
    }

    isUserRecruiter() {
        return this.user_recruiter;
    }

    getFeedId() {
        return this.feed;
    }

    getHubName() {
        return this.hub_name;
    }

    getHubSlug() {
        return this.hub_slug;
    }

    linkify(inputText) {
        let replacedText, replacePattern1, replacePattern2, replacePattern3;

        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

        //Change email addresses to mailto:: links.
        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

        return replacedText;
    }

    fullTextCharLimit: number = 350;
    fullTextOpen: boolean = false;

    getText() {
        let text = this.linkify(this.text);

        // clean
        //text = DOMPurify.sanitize(text);

        if (this.text.length <= this.fullTextCharLimit) {
            this.fullTextOpen = true;
        }

        return this.fullTextOpen ? text : text.substring(0, this.fullTextCharLimit) + '...';
    }

    toggleText() {
        this.fullTextOpen = !this.fullTextOpen;
    }

    getImage() {
        if (this.image) {
            return new Image().deserialize(this.image);
        }
        return new Image();
    }

    getStatus() {
        return this.status;
    }

    getSlug() {
        return this.slug;
    }

    isPinned() {
        return this.pinned;
    }

    getCreated() {
        return this.created;
    }

    isOgPreview() {
        return this.og_preview;
    }

    getOgTitle() {
        return this.og_title;
    }

    getOgDescription() {
        return this.og_description;
    }

    getOgImage() {
        return this.og_image;
    }

    getOgUrl() {
        return this.og_url;
    }

    getOgType() {
        return this.og_type;
    }

    getOgLocale() {
        return this.og_locale;
    }

    getStatsImpressions() {
        return this.stats_impressions;
    }

    getStatsLikes() {
        return this.stats_likes;
    }

    getStatsComments() {
        return this.stats_comments;
    }

    getStatsShares() {
        return this.stats_shares;
    }

    getStatsVotedUp() {
        return this.stats_voted_up;
    }

    getStatsVotedDown() {
        return this.stats_voted_down;
    }

    getStatsBookmarks() {
        return this.stats_bookmarks;
    }

    getStatsEngagements() {
        return this.stats_engagements;
    }

    getStatsConnections() {
        return this.stats_connections;
    }

    getStatsProfileVisits() {
        return this.stats_profile_visits;
    }

    isLiked() {
        return this.liked;
    }

    canDelete() {
        return this.can_delete;
    }

    getLatestComments() {
        let l: Post[] = [];
        if (this.latest_comments) {
            for (let i of this.latest_comments) {
                l.push(new Post().deserialize(i));
            }
        }
        return l;
    }

    getRdps() {
        let l: RelatedDataPoint[] = [];
        if (this.rdps) {
            for (let i of this.rdps) {
                l.push(new RelatedDataPoint().deserialize(i));
            }
        }
        return l;
    }

}

export class PostReported implements Deserializable {
    id: number;
    post: number;
    account_user: number;
    reason: string;
    created: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getPostId() {
        return this.post;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getReason() {
        return this.reason;
    }

    getCreated() {
        return this.created;
    }
}

export class Notification implements Deserializable {
    id: number;
    account_user: number;
    account_user_slug: string;

    avatar: string;

    object_type: string;
    object_id: number;
    object_company_id: number;
    object_name: string;
    object_description: string;
    object_slug: string;
    object_url: string;
    object_icon: string;
    object_color: string;
    object_preview: string;

    connection_status: string;
    hub_invite_status: string;

    //post_text_preview: string;
    //post_slug: string;
    //connection: number;
    //message: number;
    //badge: number;
    //badge_name: string;
    //hub: number;
    //hub_name: string;
    //hub_slug: string;
    //hub_invite: any;
    type: string;
    type_key: string;
    status: string;
    text: string;
    created: string;
    created_by_name: string;
    created_by_slug: string;
    created_by_image: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    // user

    getAccountUserId() {
        return this.account_user;
    }

    getAccountUserSlug() {
        return this.account_user_slug;
    }

    getAvatar() {
        return this.avatar;
    }

    // content type

    getObjectType() {
        return this.object_type;
    }

    getObjectId() {
        return this.object_id;
    }

    getObjectCompanyId() {
        return this.object_company_id;
    }

    getObjectName() {
        return this.object_name;
    }

    getObjectDescription() {
        return this.object_description;
    }

    getObjectSlug() {
        return this.object_slug;
    }

    getObjectUrl() {
        return this.object_url;
    }

    getObjectIcon() {
        return this.object_icon;
    }

    getObjectColor() {
        return this.object_color;
    }

    getObjectPreview() {
        return this.object_preview;
    }

    // content

    isPost() {
        return this.getObjectType() == 'post';
        //return this.getPostSlug() != null;
    }

    isConnection() {
        return this.getObjectType() == 'connection';
        //return this.getConnectionId() != null;
    }

    isBadge() {
        return this.getObjectType() == 'badge';
        //return this.badge != null;
    }

    isHub() {
        return this.getObjectType() == 'hub';
        //return this.hub;
    }

    isHubInvite() {
        return this.getObjectType() == 'hubinvite';
    }

    isJob() {
        return this.getObjectType() == 'job';
    }

    isCampaign() {
        return this.getObjectType() == 'campaign';
    }

    //

    /*getPostTextPreview() {
        return this.post_text_preview;
    }

    getPostSlug() {
        return this.post_slug;
    }

    getConnectionId() {
        return this.connection;
    }*/

    getConnectionStatus() {
        return this.connection_status;
    }

    getHubInviteStatus() {
        return this.hub_invite_status;
    }

    /*getMessageId() {
        return this.message;
    }

    getBadgeId() {
        return this.badge;
    }

    getBadgeName() {
        return this.badge_name;
    }*/

    /*getHubId() {
        return this.hub;
    }

    getHubName() {
        return this.hub_name;
    }

    getHubSlug() {
        return this.hub_slug;
    }*/

    getType() {
        return this.type;
    }

    getTypeKey() {
        return this.type_key;
    }

    getStatus() {
        return this.status;
    }

    getText() {
        return this.text;
    }

    getCreated() {
        return this.created;
    }

    getCreatedByName() {
        return this.created_by_name;
    }

    getCreatedBySlug() {
        return this.created_by_slug;
    }

    getCreatedByImage() {
        if (this.created_by_image) {
            return new Image().deserialize(this.created_by_image);
        }
        return new Image();
    }

}


export class Connection implements Deserializable {
    id: number;

    connector: number;
    connector_full_name: string;
    connector_slug: string;
    connector_image: string;
    connector_title: string;

    connected: number;
    connected_full_name: string;
    connected_slug: string;
    connected_image: string;
    connected_title: string;

    accepted: boolean;
    ignored: boolean;

    updated: string;
    created: string;

    status: string = 'pending';

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getConnectorId() {
        return this.connector;
    }

    getConnectorFullName() {
        return this.connector_full_name;
    }

    getConnectorSlug() {
        return this.connector_slug;
    }

    getConnectorImage() {
        if (this.connector_image) {
            return new Image().deserialize(this.connector_image);
        }
        return new Image();
    }

    getConnectorTitle() {
        return this.connector_title;
    }

    getConnectedId() {
        return this.connected;
    }

    getConnectedFullName() {
        return this.connected_full_name;
    }

    getConnectedSlug() {
        return this.connected_slug;
    }

    getConnectedImage() {
        if (this.connected_image) {
            return new Image().deserialize(this.connected_image);
        }
        return new Image();
    }

    getConnectedTitle() {
        return this.connected_title;
    }

    isAccepted() {
        return this.accepted;
    }

    isIgnored() {
        return this.ignored;
    }

    getUpdated() {
        return this.updated;
    }

    getCreated() {
        return this.created;
    }

    setStatusAccepted() {
        this.status = 'accepted';
    }

    setStatusIgnored() {
        this.status = 'ignored';
    }

    setStatus() {
        if (!this.isAccepted() && !this.isIgnored()) {
            this.status = 'pending';

        } else if (this.isAccepted()) {
            this.status = 'accepted';

        } else if (this.isIgnored()) {
            this.status = 'ignored';
        }
        return this.status;
    }
}


export class Badge implements Deserializable {
    name: string;
    key: string;
    description: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getName() {
        return this.name;
    }

    getKey() {
        return this.key;
    }

    getDescription() {
        return this.description;
    }

}


export class HubMember implements Deserializable {
    id: number;
    au_id: number;
    first_name: string;
    last_name: string;
    slug: string;
    image: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    isModerator(hub: Hub) {
        if (hub.moderatorsObj && hub.moderatorsObj.length > 0) {
            for (let i = 0; i < hub.moderatorsObj.length; i++) {
                if (hub.moderatorsObj[i].getId() == this.getId()) {
                    return true;
                }
            }
        }
        return false;
    }

    isAdmin(hub: Hub) {
        if (hub.adminsObj && hub.adminsObj.length > 0) {
            for (let i = 0; i < hub.adminsObj.length; i++) {
                if (hub.adminsObj[i].getId() == this.getId()) {
                    return true;
                }
            }
        }
        return false;
    }


    getId() {
        return this.id;
    }

    getAuId() {
        return this.au_id;
    }

    getFirstName() {
        return this.first_name;
    }

    getLastName() {
        return this.last_name;
    }

    getFullName() {
        return this.getFirstName() + " " + this.getLastName();
    }

    getInitials() {
        if (this.getFirstName() && this.getLastName()) {
            return this.getFirstName().charAt(0) + this.getLastName().charAt(0);
        }
        return "";
    }

    getSlug() {
        return this.slug;
    }

    getImage() {
        if (this.image) {
            return new Image().deserialize(this.image[0]);
        }
        return new Image();
    }

}

export class HubInvite implements Deserializable {
    id: number;
    hub: number;
    invited_by: number;
    invited: number;
    invited_email: string;
    email: string;
    role: string;
    accepted: string;
    ignored: string;
    created: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getHubId() {
        return this.hub;
    }

    getInvitedById() {
        return this.invited_by;
    }

    getInvitedId() {
        return this.invited;
    }

    getInvitedEmail() {
        return this.invited_email;
    }

    getEmail() {
        return this.email;
    }

    getRole() {
        return this.role;
    }

    getAccepted() {
        return this.accepted;
    }

    isAccepted() {
        return this.getAccepted();
    }

    getIgnored() {
        return this.ignored;
    }

    isIgnored() {
        return this.getIgnored();
    }

    getCreated() {
        return this.created;
    }

}


export class HubChannel implements Deserializable {
    id: number;
    hub: number;
    feed: number;
    name: string;
    slug: string;
    description: string;
    unread_posts: number;
    created: string;
    created_by: number;
    followers: any;

    loading: boolean = false;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getHubId() {
        return this.hub;
    }

    getFeedId() {
        return this.feed;
    }

    getName() {
        return this.name;
    }

    getSlug() {
        return this.slug;
    }

    getDescription() {
        return this.description;
    }

    getUnreadPosts() {
        return this.unread_posts;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }

    getFollowers() {
        return this.followers;
    }

}

// topic of interest
export class HubTopic implements Deserializable {
    id: number;
    name: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getName() {
        // replace " " with &nbsp;
        return this.name.replace(/ /g, '\u00A0');
    }
}


export class Hub implements Deserializable {
    id: number;
    name: string;
    slug: string;
    icon: string;
    image: string;
    data_points: any;
    feed: number;
    description: string;
    status: string = "public"; //by default
    join_policy: string = "open"; //by default
    invite_rule: string = "anyone"; //by default
    created: string;
    created_by: number;

    is_admin: boolean;
    is_moderator: boolean;
    is_member: boolean;
    is_in_join_requests: boolean;

    channels: any;
    channelsObj: HubChannel[] = [];

    admins: any;
    adminsObj: HubMember[] = [];

    moderators: any;
    moderatorsObj: HubMember[] = [];

    //members: any;
    //membersObj: HubMember[] = [];
    member_count: number;

    join_requests: any;
    joinRequestsObj: HubMember[] = [];

    invited_members: any;
    invitedMembersObj: HubInvite[] = [];

    grdps: any;

    score: number;

    unread_posts: number;


    deserialize(input: any) {
        Object.assign(this, input);

        if (this.channels && this.channels.length > 0)
            this.channelsObj = this.channels.map(channel => new HubChannel().deserialize(channel));

        if (this.admins && this.admins.length > 0) {
            this.adminsObj = this.admins.map(admin => new HubMember().deserialize(admin));
        }

        if (this.moderators && this.moderators.length > 0) {
            this.moderatorsObj = this.moderators.map(moderator => new HubMember().deserialize(moderator));
        }

        /*if (this.members && this.members.count > 0) { // note count is correct
            this.membersObj = this.members.results.map(member => new HubMember().deserialize(member));
        }*/

        if (this.join_requests && this.join_requests.length > 0) {
            this.joinRequestsObj = this.join_requests.map(r => new HubMember().deserialize(r));
        }

        if (this.invited_members && this.invited_members.length > 0) {
            this.invitedMembersObj = this.invited_members.map(invite => new HubInvite().deserialize(invite));
        }

        return this;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getSlug() {
        return this.slug;
    }

    getIcon() {
        if (this.icon) {
            return new Image().deserialize(this.icon);
        }
        return new Image();
    }

    getImage() {
        if (this.image) {
            return new Image().deserialize(this.image);
        }
        return new Image();
    }

    getDataPoints() {
        return this.data_points;
    }

    getFeedId() {
        return this.feed;
    }

    fullTextCharLimit: number = 150;
    fullTextOpen: boolean = false;

    getDescription() {
        if (!this.description) {
            return "";
        }
        if (this.description.length <= this.fullTextCharLimit) {
            this.fullTextOpen = true;
        }

        return this.fullTextOpen ? this.description : this.description.substring(0, this.fullTextCharLimit) + '...';
    }

    getDescriptionFull() {
        return this.description;
    }

    // get the descriotion plus data point text to get the total length
    /*getDescriptionPlusDataPoints(groupName: string) {
        let description = this.getDescription();
        let grdps = this.getGrdps(groupName);
        if (grdps && grdps.gr.length > 0) {
            for (let gr of grdps.gr) {
                description += gr.related.map(rdp => rdp.data_point.value['en']).join(" ");
            }
        }
        return description.length;
    }*/

    toggleDescription() {
        this.fullTextOpen = !this.fullTextOpen;
    }

    getStatus() {
        return this.status;
    }

    getJoinPolicy() {
        return this.join_policy;
    }

    getInviteRule() {
        return this.invite_rule;
    }

    isPublic() {
        return this.status == "public";
    }

    isPrivate() {
        return this.status == "private";
    }

    isArchived() {
        return this.status == "archived";
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }

    getChannels() {
        return this.channelsObj;
    }

    getAdmins() {
        return this.adminsObj;
    }

    getModerators() {
        return this.moderatorsObj;
    }

    /*getMembers() {
        return this.membersObj;
    }*/

    getMembersCount() {
        if (this.member_count)
            return this.member_count;
        return 0;
    }

    isAdmin() {
        return this.is_admin;
    }

    isModerator() {
        return this.is_moderator;
    }

    isMember() {
        return this.is_member;
    }

    isInJoinRequests() {
        return this.is_in_join_requests;
    }

    getRelatedDataPoints() {
        let rdps: RelatedDataPoint[] = [];

        if (this.grdps) {
            for (let grdp of this.grdps) {
                for (let gr of grdp['grdps']) {
                    for (let rdp of gr['related']) {
                        rdps.push(new RelatedDataPoint().deserialize(rdp));
                    }
                }
            }
        }
        return rdps;
    }

    getRelatedDataPointsByKey(key: string) {
        let l: RelatedDataPoint[] = [];
        if (this.getRelatedDataPoints()) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i);
                }
            }
        }
        return l;
    }

    getGrdps(byGroupName: string) {

        for (let gr of this.grdps) {
            if (gr.group_name == byGroupName) {

                let data: any = {
                    'groupName': gr.group_name,
                    //'grdps': gr.grdps,    // raw data
                    'gr': [],               // grouped data
                };

                // for every group
                for (let gdps of gr.grdps) {

                    //const gdt = gdps.group;
                    const gdt = new GroupDataType().deserialize(gdps.group);
                    const fdps = gdps.featured.map(f => new FeaturedDataPoint().deserialize(f));

                    const found = data['gr'].find(gr => gr.group.key == gdt.key);

                    if (!found) {
                        data['gr'].push({
                            'group': gdt,
                            'related': [],
                            'featured': fdps
                        });

                        for (let rdp of gdps.related)
                            data['gr'][data['gr'].length - 1].related.push(new RelatedDataPoint().deserialize(rdp));

                    }
                }
                //console.log('getGrdps', data);
                return data;
            }
        }

        //return this.rdps;
    }

    getDataPointsByKey(key: string) {
        let l: (DataPoint | null)[] = [];
        if (this.getRelatedDataPoints()) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i.getDataPoint());
                }
                // get by sub key
                if (i.getSubDataTypeKey() == key) {
                    l.push(i.getSubDataPoint());
                }
            }
        }
        return l;
    }

    getSubDataPointByKey(parentKey: string, key: string) {
        if (this.getRelatedDataPoints()) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == parentKey && i.getSubDataTypeKey() == key) {
                    return i.getSubDataPoint();
                }
            }
        }
        return null;
    }

    getSubDataPointAsString(parentKey: string, key: string, locale: string = 'en') {
        let dp = this.getSubDataPointByKey(parentKey, key);
        if (dp) {
            for (let dt of dp.data_types) {
                if (dt['key'] == key) {
                    return dp.value[locale];
                }
            }
        }
        return '';
    }

    /*getSelectedDataPoints() {
        let l = [];
        if (this.selected_data_points) {
            for (let i of this.selected_data_points) {
                l.push(new DataPoint().deserialize(i));
            }
        }
        return l;
    }*/

    getDataPointStringList(key: string, locale: string = 'en'): string[] {
        let l: any[] = [];
        for (let dp of this.getDataPointsByKey(key)) {
            if (dp) {
                for (let dt of dp.data_types) {
                    if (dt['key'] == key) {
                        l.push(dp.value[locale]);
                    }
                }
            }
        }
        return l;
    }

    getDataPointsFirstString(key: string, locale: string = 'en') {
        let l = this.getDataPointStringList(key, locale);
        if (l.length > 0)
            return l[0];
        return '';
    }

    getDataPointsAsString(key: string, locale: string = 'en') {
        let l = this.getDataPointStringList(key, locale);
        if (l.length > 0)
            return l.join(', ');
        return '';
    }

    getScore() {
        return this.score;
    }

    getUnreadPosts() {
        return this.unread_posts;
    }

}
