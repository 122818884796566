import { Deserializable } from "./deserializable";

export class Account implements Deserializable {
    id: number;
    name: string;

    getId() {
        return this.id;
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export class AccountInviteUser implements Deserializable {
    account: Account;
    user: any;
    role: string;
    email: string;
    created;
    accepted;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export class AccountIndustry implements Deserializable {
    id: number;
    account: number;
    name: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getAccount() {
        return this.account;
    }

    getName() {
        return this.name;
    }
}

export class Invite implements Deserializable {
    id: number;
    company: number;
    company_name: string;
    company_user_role: string;
    job: number;
    job_title: string;
    from_au: number;
    from_au_email: string;
    from_au_full_name: string;
    to_au: number;
    email: string;
    name: string;
    type: string;
    created: string;
    last_reminded: string;
    accepted: string;
    ignored: string;
    page_name: string;
    page_slug: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getCompanyId() {
        return this.company;
    }

    getCompanyName() {
        return this.company_name;
    }

    getCompanyUserRole() {
        return this.company_user_role;
    }

    getJobId() {
        return this.job;
    }

    getJobTitle() {
        return this.job_title;
    }

    getFromAuId() {
        return this.from_au;
    }

    getFromAuEmail() {
        return this.from_au_email;
    }

    getFromAuFullName() {
        if (this.from_au_full_name) {
            return this.from_au_full_name;
        }
        return this.from_au_email;
    }

    getToAuId() {
        return this.to_au;
    }

    getEmail() {
        return this.email;
    }

    getName() {
        return this.name;
    }

    getType() {
        return this.type;
    }

    getCreated() {
        return this.created;
    }

    getLastReminded() {
        return this.last_reminded;
    }

    getAccepted() {
        return this.accepted;
    }

    getIgnored() {
        return this.ignored;
    }

    getPageName() {
        return this.page_name;
    }

    getPageSlug() {
        return this.page_slug;
    }
}
