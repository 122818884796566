import { Deserializable } from "./deserializable";
import { map } from 'rxjs/operators';


export class PayBank implements Deserializable {
    id: number;
    account: number;
    account_user: number;
    failed: any;
    created: any;
    bank: string;
    clearing_number: number;
    account_number: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    isPrimary(accountUser) {
        if (!accountUser) {
            return false;
        }
        if (accountUser.getPrimaryPaymentMethodType() != 'bank') {
            return false;
        }
        if (accountUser.getPrimaryPaymentMethod()) {
            if (this.getId() != accountUser.getPrimaryPaymentMethod().getId()) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }

    getId() {
        return this.id;
    }

    getAccountId() {
        return this.account;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getFailed() {
        return this.failed;
    }

    getCreated() {
        return this.created;
    }

    getBank() {
        return this.bank;
    }

    getClearingNumber() {
        return this.clearing_number;
    }

    getAccountNumber() {
        return this.account_number;
    }

    getBic() {
        return undefined;
    }

    getIban() {
        return undefined;
    }
}

export class PayIban implements Deserializable {
    id: number;
    account: number;
    account_user: number;
    failed: any;
    created: any;
    bic: string;
    iban: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    isPrimary(accountUser) {
        if (!accountUser) {
            return false;
        }
        if (accountUser.getPrimaryPaymentMethodType() != 'iban') {
            return false;
        }
        if (accountUser.getPrimaryPaymentMethod()) {
            if (this.getId() != accountUser.getPrimaryPaymentMethod().getId()) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    getId() {
        return this.id;
    }

    getAccountId() {
        return this.account;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getFailed() {
        return this.failed;
    }

    getCreated() {
        return this.created;
    }

    getBank() {
        return undefined;
    }

    getClearingNumber() {
        return undefined;
    }

    getAccountNumber() {
        return undefined;
    }

    getBic() {
        return this.bic;
    }

    getIban() {
        return this.iban;
    }
}


export class Payment implements Deserializable {
    id: number;
    account: number;
    account_user: number;
    batch: number;
    voucher_number: number;
    country: number;
    country_code: string;
    currency: number;
    currency_code: string;

    hash: string;

    payment_method: any;

    method_details: string;
    company_name: string;
    vat_number: string;

    salary: boolean;
    full_name: string;
    receipt_by_us: boolean;
    personal_number: string;
    salary_gross: number;
    salary_net: number;

    amount: number;
    vat: number;
    total: number;

    created: any;
    paid: any;
    cancelled: any;


    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getHash() {
        return this.hash;
    }

    getAccountId() {
        return this.account;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getBatchId() {
        return this.batch;
    }

    getVoucherNumber() {
        return this.voucher_number;
    }

    getCountryId() {
        return this.country;
    }

    getCurrencyId() {
        return this.currency;
    }

    getCountryCode() {
        return this.country_code;
    }

    getCurrencyCode() {
        return this.currency_code;
    }

    getPaymentMethod() {
        if (this.payment_method.bank) {
            return new PayBank().deserialize(this.payment_method);
        }
        return null;
    }

    getMethodDetails() {
        return this.method_details;
    }

    getCompanyName() {
        return this.company_name;
    }

    getVatNumber() {
        return this.vat_number;
    }


    isSalary() {
        return this.salary;
    }

    getFullName() {
        return this.full_name;
    }

    isReceiptByUs() {
        return this.receipt_by_us;
    }

    getPersonalNumber() {
        return this.personal_number;
    }

    getSalaryGross() {
        return this.salary_gross;
    }

    getSalaryNet() {
        return this.salary_net;
    }


    getAmount() {
        return this.amount;
    }

    getVat() {
        return this.vat;
    }

    getTotal() {
        return this.total;
    }

    getCreated() {
        return this.created;
    }

    getPaid() {
        return this.paid;
    }

    getCancelled() {
        return this.cancelled;
    }
}


export class PaymentFailed implements Deserializable {
    account_user: number;
    payment: number;
    reason: string;
    note: string;
    notification: boolean;
    failed: any;
    created: any;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getPaymentId() {
        return this.payment;
    }

    getReason() {
        return this.reason;
    }

    getNote() {
        return this.note;
    }

    getNotification() {
        return this.notification;
    }

    getFailed() {
        return this.failed;
    }

    getCreated() {
        return this.created;
    }
}
