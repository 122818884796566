import { Component, OnInit, Input, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

    @Input() size: string = 'md'; // sm, md, lg
    @Input() title: string;
    @Input() customClass: string = '';

    @Input() set setCanClose(canClose: boolean) {
        this.canClose = canClose;
    }

    @Output() closed = new EventEmitter<boolean>();

    modal: any;
    content: any;

    canClose: boolean = true;

    constructor(private elementRef: ElementRef) { }

    ngOnInit(): void {
        this.modal = this.elementRef.nativeElement.querySelector('.modal');
        this.content = this.elementRef.nativeElement.querySelector('.modal-content');
    }

    // close on escape
    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.close();
    }

    isOpen() {
        if (this.modal.style.display == "block")
            return true;
        return false;
    }

    open() {
        this.modal.style.display = "block";
    }

    close() {
        if (this.canClose) {
            this.closed.emit(true);
            this.modal.style.display = "none";
        }
    }

}
