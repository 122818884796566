import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ModalComponent } from './modal.component';


@NgModule({
  declarations: [
    ModalComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
        extend: true
    }),
  ],
  exports: [
    ModalComponent,
  ]
})
export class ModalModule { }
