import { Deserializable } from "./deserializable";

import { Account } from './account'; //, AccountIndustry
import { RelatedDataPoint, DataPoint, GroupDataType, FeaturedDataPoint } from './collection';
import { Country } from './country';
import { Currency } from './currency';
import { Image } from './media';
import { Badge } from './network';
import { PayBank, PayIban } from './payment';

// NOTE not used any more
/*
export class User implements Deserializable {
    id: number;

    token: string;

    username: string;
    first_name: string;
    last_name: string;
    email: string;

    profile: {
        using_account: Account;
        using_account_user: number; // AccountUser
        timezone: string;
    };

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getAccount() {
        return new Account().deserialize(this.profile.using_account);
    }

    getAccountUser() {
        return new AccountUser().deserialize(this.profile.using_account_user);
    }

    getAccountUserId() {
        return this.profile.using_account_user;
    }

    getEmail() {
        return this.email;
    }

    getFullName() {
        return this.first_name + ' ' + this.last_name;
    }

    getFirstName(): string {
        return this.first_name;
    }

    getLastName(): string {
        return this.last_name;
    }

    getUsername() {
        return this.username;
    }
}*/

export interface ICV {
    image?: string;
    video?: string;
    first_name: string;
    last_name: string;
    city?: string;
    country?: string;
    description?: string;
    languages: ILanguage[];
    work: IWork[];
    educations: IEducation[];
    skills: ISkill[];
    experiences: IExperience[];
    recommendations: IRecommendation[];
    certificates: ICertificate[];
    interests: IInterest[];
}

export interface ILanguage {
    name: string;
    level: string;
}

export interface IWork {
    title: string;
    company: string;
    type: string;
    city: string;
    country: string;
    start: string;
    end: string;
    description: string;
}

export interface IEducation {
    school: string;
    degree?: string;
    field?: string;
    city?: string;
    country?: string;
    start: string;
    end: string;
    description: string;
}

export interface ISkill {
    name: string;
    rating?: string;
}

export interface IExperience {
    name: string;
    years?: number;
    description?: string;
}

export interface IRecommendation {
    name: string;
    title: string;
    company: string;
    city: string;
    country: string;
    rating: string;
    start?: string;
    end?: string;
    description: string;
}

export interface ICertificate {
    name: string;
    description?: string;
    date?: string;
}

export interface IInterest {
    name: string;
    description?: string;
    date?: string;
}


export class AccountUser implements Deserializable {
    id: number;

    account: Account;
    user: any;
    role: string;
    country: Country;
    currency: Currency;
    //industries: AccountIndustry[];

    stripe_customer_id: string;
    stripe_customer_currency: string;

    //cv: ICV;
    cv: any; // only used in DCP
    cv_file: string;
    hash: string;
    slug: string;
    image: string; // only used in DCP

    // from the AccountUserSmallSerializer
    first_name: string;
    last_name: string;
    email: string;

    company_name: string;
    vat_number: string;
    personal_number: string;
    phone1: string;
    phone2: string;
    linkedin: string;
    headline: string;
    pitch: string;
    interests: string;
    born: string;
    address1: string;
    address2: string;
    zip_code: string;
    city: string;

    login_provider: string;

    payment_form: string;
    pause_payments: boolean;
    payment_date: any;
    payment_failed_note: string;
    primary_payment_method: any;
    primary_payment_show: boolean;
    balance_invoiced: number;
    balance_available: number;
    balance_invoiced_gross: number;
    balance_available_gross: number;

    referral_clicks_30d: number;
    referral_invites_30d: number;
    referral_job_applications_30d: number;

    companies: any;
    admin_for: any;
    manager_for: any;
    partner_for: any;

    consultant_id: number;
    seller_id: number;
    subcontractor_id: number;
    employment_id: number;
    is_employed: boolean;
    has_employment: boolean;
    latest_employment_form: string;
    has_invites: boolean;
    can_edit: boolean;
    connection_count: number;
    viewer_count: number;

    is_consultant: boolean;
    is_premium: boolean;
    is_recruiter: boolean;

    images: any;
    main_image: any;
    user_skill_sets: any;
    user_experiences: any;

    related_data_points: any;
    //selected_data_points: any;

    matching: number;
    //matching_dp: any;

    skills_string: string;

    chat_unread_messages: number;

    notify_email_hourly_unread_messages: boolean;
    notify_email_hourly_unread_notifications: boolean;
    notify_email_daily_unread_messages: boolean;
    notify_email_weekly_unread_messages: boolean;

    karma: number;

    profile_completed: number;

    removed: any;
    created: any;

    dpStatus: any = [];
    dpBasicLanguage: any = [];
    dpExperienceSkills: any = [];
    dpExperienceTechStack: any = [];
    dpExperienceTools: any = [];

    subscriptions: any;

    deserialize(input: any): this {
        if (!input) {
            return this;
        }
        Object.assign(this, input);

        this.country = new Country().deserialize(this.country);
        this.currency = new Currency().deserialize(this.currency);

        this.dpStatus = this.getDataPoints('basic_status');
        this.dpBasicLanguage = this.getDataPoints('basic_language');
        this.dpExperienceSkills = this.getDataPoints('experience_skillset');
        this.dpExperienceTechStack = this.getDataPoints('experience_tech_stack');
        this.dpExperienceTools = this.getDataPoints('experience_tools');

        return this;
    }

    getId() {
        return this.id;
    }

    getAccount() {
        return new Account().deserialize(this.account);
    }

    getUser() {
        return this.user;
    }

    getUsername() {
        return this.user.username;
    }

    getRole() {
        if(this.role == '0') {
            return 'general';

        } else if (this.role == '1') {
            return 'admin';

        } else if(this.role == '2') {
            return 'manager';

        } else if(this.role == '3') {
            return 'viewer';

        } else if(this.role == '4') {
            return 'consultant';

        // not using role here since we want general users to be consultants too
        //} else if(this.is_consultant) {
        //    return 'consultant';

        } else if(this.role == '5') {
            return 'seller';

        } else if(this.role == '6') {
            return 'economist';

        } else if(this.role == '7') {
            return 'agent';

        } else {
            return 'general';
        }

        /*else if(this.role == '7') {
            return 'recruiter';

        } else if(this.role == '8') {
            return 'team_leader';

        } else if(this.role == '9') {
            return 'seller_recruiter';
        }*/
    }

    isPremium() {
        return this.is_premium;
    }

    isRecruiter() {
        return this.is_recruiter;
    }

    getConsultantId() {
        return this.consultant_id;
    }

    getSellerId() {
        return this.seller_id;
    }

    getCountryId() {
        return this.country.id;
    }

    getCountryCode() {
        return this.country.code;
    }

    getCountryVat() {
        return this.country.getVat();
    }

    getCurrencyId() {
        return this.currency.id;
    }

    getCurrencyCode() {
        return this.currency.getCode();
    }

    isCountryInEu() {
        return this.country.inEu();
    }

    getSubcontractorId() {
        return this.subcontractor_id;
    }

    // NOTE a user can have an employment object but not be employed at the moment
    getEmploymentId() {
        return this.employment_id;
    }

    /*getIndustries() {
        if (this.industries) {
            let objs = [];
            for (let industry of this.industries) {
                objs.push(new AccountIndustry().deserialize(industry));
            }
            return objs;
        }
        return null;
    }*/

    getStripeCustomerId() {
        return this.stripe_customer_id;
    }

    getStripeCustomerCurrency() {
        return this.stripe_customer_currency;
    }

    getCV() {
        return this.cv;
    }

    getCvFile() {
        return this.cv_file;
    }

    getProfileStatus(): string[] {
        let s: string[] = [];

        if (!this.getMainImage()) {
            s.push("Add a profile image");
        }
        if (!this.getFirstName()) {
            s.push("Add your first name");
        }
        if (!this.getLastName()) {
            s.push("Add your last name");
        }
        if (!this.getCity()) {
            s.push("Add the city you live in");
        }
        if (!this.getPitch()) {
            s.push("Add a short pitch about yourself that describes who about are");
        }
        if (!this.getUserSkillSets()) {
            s.push("Add your skills");
        }
        if (!this.getUserExperiences()) {
            s.push("Add your work and education experiences");
        }
        if (!this.getInterests()) {
            s.push("Add your interests");
        }
        if (!this.getPhone1()) {
            s.push("Add your phone number");
        }
        return s;
    }

    // returns a percentage based on the getProfileStatus function
    getProfileStatusCompletedPercentage() {
        // 9 is the number of messages in getProfileStatus
        let s = 9 - this.getProfileStatus().length;
        if (s > 0 && s != 9) {
            return (s / 9) * 100;
        }
        return 0;
    }

    // NOTE not used any more, replaced by getProfileCompleted()
    getProfileRequired(): string[] {
        let s: string[] = [];

        if (!this.getMainImage()) {
            s.push("Profile image");
        }
        if (!this.getFirstName() || !this.getLastName()) {
            s.push("First and last name");
        }
        //if (!this.getPitch()) {
        //    s.push("A short pitch about yourself");
        //}
        if (this.getUserSkillSets().length == 0) {
            s.push("Your skills");
        }
        if (this.getUserExperiences().length == 0) {
            s.push("Your work and education experiences");
        }
        if (!this.getPhone1()) {
            s.push("Your phone number");
        }
        if (!this.getCity()) {
            s.push("The city you live in");
        }
        return s;
    }

    getHash() {
        return this.hash;
    }

    getSlug() {
        return this.slug;
    }

    getImage() {
        return this.image;
    }

    isGeneral() {
        if(this.getRole() == 'general')
            return true;
        return false;
    }

    isAgent() {
        if(this.getRole() == 'agent')
            return true;
        return false;
    }

    isAdmin() {
        if(this.getRole() == 'admin')
            return true;
        return false;
    }

    isManager() {
        if(this.getRole() == 'manager')
            return true;
        return false;
    }

    isConsultant() {
        if  (this.getConsultantId()) {
        //if(this.getRole() == 'consultant') {
            return true;
        }
        return false;
    }

    isSeller() {
        if(this.getRole() == 'seller')
            return true;
        return false;
    }

    isStaff() {
        if(this.isAdmin() || this.isManager() || this.isSeller()) {
            return true;
        }
        return false;
    }

    // if subcontractor_id returns an id then we have a subcontractor
    isSubcontractor() {
        if (this.subcontractor_id) {
            return true;
        }
        return false;
    }

    isEmployed() {
        return this.is_employed;
    }

    hasEmployment() {
        return this.has_employment;
    }

    getLatestEmploymentForm() {
        return this.latest_employment_form;
    }

    hasInvites() {
        return this.has_invites;
    }

    canEdit() {
        return this.can_edit;
    }

    getConnectionCount() {
        return this.connection_count;
    }

    getViewerCount() {
        return this.viewer_count;
    }

    getFirstName() {
        if (this.first_name) {
            return this.first_name;
        } else {
            let u = this.getUser();
            if (u)
                return u.first_name;
            else
                return null;
        }
    }

    getLastName() {
        if (this.last_name) {
            return this.last_name;
        } else {
            let u = this.getUser();
            if (u)
                return u.last_name;
            else
                return null;
        }
    }

    getFullName() {
        return this.getFirstName() + " " + this.getLastName();
    }

    getInitials() {
        if (this.getFirstName() && this.getLastName()) {
            return this.getFirstName()[0] + this.getLastName()[0];
        }
        return '';
    }

    getEmail() {
        if (this.email) {
            return this.email;

        } else {
            let u = this.getUser();
            if (u)
                return u.email;
            else
                return null;
        }
    }

    getCompanyName() {
        return this.company_name;
    }

    getVatNumber() {
        return this.vat_number;
    }

    getPersonalNumber() {
        return this.personal_number;
    }

    getPhone1() {
        return this.phone1;
    }

    getPhone2() {
        return this.phone2;
    }

    getLinkedIn() {
        return this.linkedin;
    }

    getHeadline() {
        return this.headline;
    }

    getPitch() {
        return this.pitch;
    }

    getInterests() {
        return this.interests;
    }

    getBornDate() {
        return this.born;
    }

    getAddress1() {
        return this.address1;
    }

    getAddress2() {
        return this.address2;
    }

    getZipCode() {
        return this.zip_code;
    }

    getCity() {
        return this.city;
    }

    getLoginProvider() {
        return this.login_provider;
    }

    getPaymentForm() {
        return this.payment_form;
    }

    isCompany() {
        if (this.getPaymentForm() == 'company') {
            return true;
        }
        return false;
    }

    isSelfEmployed() {
        if (this.getPaymentForm() == 'self-employed') {
            return true;
        }
        return false;
    }

    getPausePayments() {
        return this.pause_payments;
    }

    getPaymentDate() {
        return this.payment_date;
    }

    getPaymentFailedNote() {
        return this.payment_failed_note;
    }

    getPaymentFailedShow() {
        return this.primary_payment_show;
    }

    getPrimaryPaymentMethod() {
        if (this.primary_payment_method && this.primary_payment_method.bank) {
            return new PayBank().deserialize(this.primary_payment_method);
        }
        if (this.primary_payment_method && this.primary_payment_method.iban) {
            return new PayIban().deserialize(this.primary_payment_method);
        }
        return null;
    }

    getPrimaryPaymentMethodType() {
        if (this.primary_payment_method && this.primary_payment_method.bank) {
            return 'bank';
        }
        if (this.primary_payment_method && this.primary_payment_method.iban) {
            return 'iban';
        }
        return null;

    }

    hasPrimaryPaymentMethod() {
        if (this.primary_payment_method) {
            return true;
        }
        return false;
    }

    getBalanceInvoiced() {
        return this.balance_invoiced;
    }

    getBalanceAvailable() {
        return this.balance_available;
    }

    getBalanceInvoicedGross() {
        return this.balance_invoiced_gross;
    }

    getBalanceAvailableGross() {
        return this.balance_available_gross;
    }

    getReferralClicksLast30d() {
        return this.referral_clicks_30d || 0;
    }

    getReferralInvitesLast30d() {
        return this.referral_invites_30d || 0;
    }

    getReferralJobApplicationsLast30d() {
        return this.referral_job_applications_30d || 0;
    }

    getCompanies() {
        return this.companies;
    }

    getFirstCompany() {
        if (this.getCompanies())
            return this.companies[0];
        return null;
    }

    getActiveSubscription(subscriptionKey: string, companyId: number | null) {
        // account user
        if (!companyId) {
            if (this.subscriptions?.length > 0) {
                for (let sub of this.subscriptions) {
                    if (sub.key == subscriptionKey && (sub.is_active || sub.is_trial)) {
                        return sub;
                    }
                }
            }

        } else {
            // company
            const company = this.getCompanies()?.find(c => c.id == companyId);
            if (company && company.subscriptions?.length > 0) {
                for (let sub of company.subscriptions) {
                    if (sub.key == subscriptionKey && (sub.is_active || sub.is_trial)) {
                        return sub;
                    }
                }
            }
        }
        return null;
    }

    hasAccess(subscriptionKey: string, companyId: number | null) {
        if (this.getActiveSubscription(subscriptionKey, companyId))
            return true;
        return false;
    }

    // returns the subscriptions quantity
    getSubscriptionQuantity(subscriptionKey: string, companyId: number) {
        if (this.hasAccess(subscriptionKey, companyId)) {
            const company = this.getCompanies().find(c => c.id == companyId);
            const subscription = company.subscriptions.find(s => s.key == subscriptionKey && (s.is_active || s.is_trial));
            return subscription.quantity
        }
        return 0;
    }

    hasUnpaidSubscription() {
        if (this.subscriptions && this.subscriptions.length > 0) {
            for (let sub of this.subscriptions) {
                if (sub.stripe_status == 'past_due' || sub.stripe_status == 'unpaid' || sub.stripe_status == 'incomplete' || sub.stripe_status == 'incomplete_expired') {
                    return sub.id;
                }
            }
        }
        return false;
    }

    /*isPremiumType(subscription: any) {
        //if (subscription.type == 'bp30' || subscription.type == 'bp100' || subscription.type == 'bp200') {
        if (subscription.type == 'bp') {
            return true;
        }
        return false;
    }

    // is user is part of a company that is subscribed to Business Premium
    // this does not mean that the user has a active slot
    // active premium subscriptions of type bp
    isPremium(company) {
        if (company.subscriptions.length > 0) {
            for (let sub of company.subscriptions) {
                if (this.isPremiumType(sub) && sub.is_active) {
                    return true;
                }
            }
        }
        return false;
    }

    isTrial(company) {
        if (company.subscriptions.length > 0) {
            for (let sub of company.subscriptions) {
                if (this.isPremiumType(sub) && sub.is_trial) {
                    return true;
                }
            }
        }
        return false;
    }*/

    getAdminFor() {
        return this.admin_for;
    }

    getManagerFor() {
        return this.manager_for;
    }

    getPartnerFor() {
        return this.partner_for;
    }

    isCompanyAdminFor(companyId: number) {

        let companies = this.getCompanies();
        let company = companies?.find(c => c.id == companyId);

        if (company && company.role == 'admin') {
            return true;
        }

        /*console.log(companies);
        if(this.getAdminFor().find(c => c.id == companyId)) {
            return true;
        }*/
        return false;
    }

    isCompanyManagerFor(companyId: number) {
        let companies = this.getCompanies();
        let company = companies?.find(c => c.id == companyId);

        if (company && company.role == 'manager') {
            return true;
        }

        /*if(this.getManagerFor().find(c => c.id == companyId)) {
            return true;
        }*/
        return false;
    }

    isCompanyPartnerFor(companyId: number) {
        let companies = this.getCompanies();
        let company = companies?.find(c => c.id == companyId);

        if (company && company.role == 'partner') {
            return true;
        }

        /*if(this.getPartnerFor().find(c => c.id == companyId)) {
            return true;
        }*/

        return false;
    }

    getAngularLocale() {
        if (this.country) {
            return this.country.getAngularLocale();
        }

        return 'en';
    }

    getLocale() {
        if (this.country) {
            return this.country.getAngularLocale();
        }

        return 'en';
    }

    getImages() {
        if (this.images) {
            let r: Image[] = [];
            for (let i of this.images) {
                r.push(new Image().deserialize(i));
            }
            return r;
        }
        return [];
    }

    getMainImage() {
        if (this.main_image) {
            return new Image().deserialize(this.main_image);
        }
        return new Image();
    }

    getUserSkillSets() {
        let l: UserSkillSet[] = [];
        for (let i of this.user_skill_sets) {
            l.push(new UserSkillSet().deserialize(i));
        }
        return l;
    }

    getUserExperiences() {
        let l: UserExperience[] = [];
        if (this.user_experiences) {
            for (let i of this.user_experiences) {
                l.push(new UserExperience().deserialize(i));
            }
        }
        return l;
    }

    getRelatedDataPoints() {
        let l: RelatedDataPoint[] = [];
        if (this.related_data_points) {
            for (let i of this.related_data_points) {
                l.push(new RelatedDataPoint().deserialize(i));
            }
        }
        return l;
    }

    getRelatedDataPointsByKey(key: string) {
        let l: RelatedDataPoint[] = [];
        if (this.related_data_points) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i);
                }
            }
        }
        if (key == 'experience_roles') {
            l.sort((a, b) => {
                if (a.start_date > b.start_date) {
                    return -1; // Return -1 to indicate 'a' should be sorted before 'b'
                } else if (a.start_date < b.start_date) {
                    return 1; // Return 1 to indicate 'b' should be sorted before 'a'
                //} else if (!a.end_date) {
                //    return 1; // Return -1 to indicate 'a' should be sorted before 'b'
                } else {
                    return 0; // Return 0 to indicate 'a' and 'b' have the same sorting order
                }
            });
        }

        return l;
    }

    getDataPointsByKey(key: string) {
        let l: (DataPoint | null)[] = [];
        if (this.related_data_points) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i.getDataPoint());
                }
                // get by sub key
                if (i.getSubDataTypeKey() == key) {
                    l.push(i.getSubDataPoint());
                }
            }
        }
        return l;
    }

    getSubDataPointByKey(parentKey: string, key: string) {
        if (this.getRelatedDataPoints()) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == parentKey && i.getSubDataTypeKey() == key) {
                    return i.getSubDataPoint();
                }
            }
        }
        return null;
    }

    getSubDataPointAsString(parentKey: string, key: string, locale: string = 'en') {
        let dp = this.getSubDataPointByKey(parentKey, key);
        if (dp) {
            for (let dt of dp.data_types) {
                if (dt['key'] == key) {
                    return dp.value[locale];
                }
            }
        }
        return '';
    }

    /*getSelectedDataPoints() {
        let l = [];
        if (this.selected_data_points) {
            for (let i of this.selected_data_points) {
                l.push(new DataPoint().deserialize(i));
            }
        }
        return l;
    }*/

    getDataPoints(key: string, locale: string = 'en'): string[] {
        let l: any[] = [];
        let mock = {};
        let dp: DataPoint | null;

        if (this.related_data_points) {
            for (let rdp of this.getRelatedDataPoints()) {
                mock = {
                    'id': null,
                    'value': null,
                    'experience': null
                };
                if (rdp.getDataTypeKey() == key) {
                    dp = rdp.getDataPoint();
                    // create mock
                    if (dp) {
                        mock['id'] = dp.getId();
                        mock['value'] = dp.getValue(locale);
                        if (rdp.getExperience())
                            mock['experience'] = rdp.getExperience();
                    }
                    l.push(mock);
                }
            }
        }
        return l;
    }

    getDataPointStringList(key: string, locale: string = 'en'): string[] {
        let l: string[] = [];
        for (let dp of this.getDataPointsByKey(key)) {
            if (dp) {
                if (dp.getDataTypeKeys().includes(key))
                    l.push(dp.getValue(locale));
            }
        }
        return l;
    }

    getDataPointsFirstString(key: string, locale: string = 'en') {
        let l = this.getDataPointStringList(key, locale);
        if (l.length > 0)
            return l[0];
        return '';
    }

    getDataPointsAsString(key: string, locale?: string, dataPointIdsToHighlight?: any) {
        // TODO dataPointIdsToHighlight is not implemented
        if (!locale)
            locale = 'en';

        let l = this.getDataPointStringList(key, locale);
        if (l.length > 0)
            return l.join(', ');
        return '';
    }

    getMatchingScore() {
        return this.matching;
    }

    /*getMatchingDataPoint() {
        return new DataPoint().deserialize(this.matching_dp);
    }*/

    getSkillsString() {
        return this.skills_string;
    }

    getChatUnreadMessagesCount() {
        return this.chat_unread_messages;
    }

    getNotifyEmailHourlyUnreadMessages() {
        return this.notify_email_hourly_unread_messages;
    }

    getNotifyEmailHourlyUnreadNotifications() {
        return this.notify_email_hourly_unread_notifications;
    }

    getNotifyEmailDailyUnreadMessages() {
        return this.notify_email_daily_unread_messages;
    }

    getNotifyEmailWeeklyUnreadMessages() {
        return this.notify_email_weekly_unread_messages;
    }

    getKarma() {
        return this.karma;
    }

    // 0-1 in percent
    getProfileCompleted() {
        return this.profile_completed * 100;
    }

    getRemoved() {
        return this.removed;
    }

    getCreated() {
        return this.created;
    }

}
export class AccountUserSkillSet implements Deserializable {
    id: number;
    account: number;
    account_user: number;
    job: number;
    skill_name: string;
    years_experience: number;
    level: string;

    checked: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getAccountId() {
        return this.account;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getJobId() {
        return this.job;
    }

    getSkillName() {
        return this.skill_name;
    }

    getYearsExperience() {
        return this.years_experience;
    }

    getLevel() {
        return this.level;
    }
}

export class AccountUserPublic implements Deserializable {
    id: number;
    consultant_id: number;
    slug: string;
    first_name: string;
    last_name: string;
    email: string;
    phone1: string;
    linkedin: string;
    headline: string;
    pitch: string;
    interests: string;
    born: string;
    city: string;
    role: string;
    is_premium: boolean;
    is_recruiter: boolean;
    can_edit: boolean;
    can_connect: boolean;
    can_accept_connection: boolean;
    connection_id: number;
    connection_count: number;
    connection_pending: boolean;
    connection_accepted: boolean;
    badges: any;
    badgeObjects: Badge[];
    viewer_count: number;
    karma: number;
    created: string;

    cv_file: string;

    images: any;
    main_image: any;
    /*user_skill_sets: any;
    user_experiences: any;*/

    //related_data_points: any;
    grdps: any;
    //selected_data_points: any;

    rdp_basic_job_title: string;
    rdp_basic_current_location: string;

    skills_string: string;

    profile_completed: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getConsultantId() {
        return this.consultant_id;
    }

    getSlug() {
        return this.slug;
    }

    getFirstName() {
        return this.first_name;
    }

    getLastName() {
        return this.last_name;
    }

    getFullName() {
        return this.getFirstName() + ' ' + this.getLastName();
    }

    getEmail() {
        return this.email;
    }

    getPhone() {
        return this.phone1;
    }

    getLinkedIn() {
        return this.linkedin;
    }

    getHeadline() {
        return this.headline;
    }

    getPitch() {
        return this.pitch;
    }

    getInterests() {
        return this.interests;
    }

    // returns string
    getBornDate() {
        return this.born;
    }

    getCity() {
        return this.city;
    }

    getCreated() {
        return this.created;
    }

    getRole() {
        if(this.role == '0') {
            return 'general';

        } else if (this.role == '1') {
            return 'admin';

        } else if(this.role == '2') {
            return 'manager';

        } else if(this.role == '3') {
            return 'viewer';

        } else if(this.role == '4') {
            return 'consultant';

        } else if(this.role == '5') {
            return 'seller';

        } else if(this.role == '6') {
            return 'economist';

        } else if(this.role == '7') {
            return 'agent';

        } else {
            return 'general';
        }

        /*else if(this.role == '7') {
            return 'recruiter';

        } else if(this.role == '8') {
            return 'team_leader';

        } else if(this.role == '9') {
            return 'seller_recruiter';
        }*/
    }

    isPremium() {
        return this.is_premium;
    }

    isRecruiter() {
        return this.is_recruiter;
    }

    canEdit() {
        return this.can_edit;
    }

    canConnect() {
        return this.can_connect;
    }

    canAcceptConnection() {
        return this.can_accept_connection;
    }

    getConnectionId() {
        return this.connection_id;
    }

    getConnectionCount() {
        return this.connection_count;
    }

    isConnectionPending() {
        return this.connection_pending;
    }

    isConnectionAccepted() {
        return this.connection_accepted;
    }

    getBadges() {
        return this.badgeObjects;
    }

    getBadgesObject() {
        let r: Badge[] = [];
        if (this.badges) {
            for (let i of this.badges) {
                r.push(new Badge().deserialize(i));
            }
        }
        return r;
    }

    getViewerCount() {
        return this.viewer_count;
    }

    getKarma() {
        return this.karma;
    }

    isGeneral() {
        if(this.getRole() == 'general')
            return true;
        return false;
    }

    isAgent() {
        if(this.getRole() == 'agent')
            return true;
        return false;
    }

    isAdmin() {
        if(this.getRole() == 'admin')
            return true;
        return false;
    }

    isManager() {
        if(this.getRole() == 'manager')
            return true;
        return false;
    }

    isConsultant() {
        //if(this.getConsultantId()) {
        if(this.getRole() == 'consultant') {
            return true;
        }
        return false;
    }

    isSeller() {
        if(this.getRole() == 'seller')
            return true;
        return false;
    }

    getImages() {
        if (this.images) {
            let r: Image[] = [];
            for (let i of this.images) {
                r.push(new Image().deserialize(i));
            }
            return r;
        }
        return [];
    }

    getMainImage() {
        if (this.main_image) {
            return new Image().deserialize(this.main_image);
        }
        if (this.images) {
            if (this.getImages().length > 0) {
                return this.getImages()[0];
            }
        }
        return new Image();
    }

    /*getUserSkillSets() {
        let l = [];
        for (let i of this.user_skill_sets) {
            l.push(new UserSkillSet().deserialize(i));
        }
        return l;
    }

    getUserExperiences() {
        let l = [];
        if (this.user_experiences) {
            for (let i of this.user_experiences) {
                l.push(new UserExperience().deserialize(i));
            }
        }
        return l;
    }*/

    getRelatedDataPoints() {
        let rdps: RelatedDataPoint[] = [];

        if (this.grdps) {
            for (let grdp of this.grdps) {
                for (let gr of grdp['grdps']) {
                    for (let rdp of gr['related']) {
                        rdps.push(new RelatedDataPoint().deserialize(rdp));
                    }
                }
            }
        }
        return rdps;
    }

    getRelatedDataPointsByKey(key: string) {
        let l: RelatedDataPoint[] = [];
        if (this.getRelatedDataPoints()) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i);
                }
            }
        }
        return l;
    }

    getGrdps(byGroupName: string) {

        for (let gr of this.grdps) {
            if (gr.group_name == byGroupName) {

                let data: any = {
                    'groupName': gr.group_name,
                    //'grdps': gr.grdps,    // raw data
                    'gr': [],               // grouped data
                };

                // for every group
                for (let gdps of gr.grdps) {

                    //const gdt = gdps.group;
                    const gdt = new GroupDataType().deserialize(gdps.group);
                    const fdps = gdps.featured.map(f => new FeaturedDataPoint().deserialize(f));

                    const found = data['gr'].find(gr => gr.group.key == gdt.key);

                    if (!found) {
                        data['gr'].push({
                            'group': gdt,
                            'related': [],
                            'featured': fdps
                        });

                        for (let rdp of gdps.related)
                            data['gr'][data['gr'].length - 1].related.push(new RelatedDataPoint().deserialize(rdp));

                    }
                }
                //console.log('getGrdps', data);
                return data;
            }
        }

        //return this.rdps;
    }

    getDataPointsByKey(key: string) {
        let l: (DataPoint | null)[] = [];
        if (this.getRelatedDataPoints()) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i.getDataPoint());
                }
                // get by sub key
                if (i.getSubDataTypeKey() == key) {
                    l.push(i.getSubDataPoint());
                }
            }
        }
        return l;
    }

    getSubDataPointByKey(parentKey: string, key: string) {
        if (this.getRelatedDataPoints()) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == parentKey && i.getSubDataTypeKey() == key) {
                    return i.getSubDataPoint();
                }
            }
        }
        return null;
    }

    getSubDataPointAsString(parentKey: string, key: string, locale: string = 'en') {
        let dp = this.getSubDataPointByKey(parentKey, key);
        if (dp) {
            for (let dt of dp.data_types) {
                if (dt['key'] == key) {
                    return dp.value[locale];
                }
            }
        }
        return '';
    }

    /*getSelectedDataPoints() {
        let l = [];
        if (this.selected_data_points) {
            for (let i of this.selected_data_points) {
                l.push(new DataPoint().deserialize(i));
            }
        }
        return l;
    }*/

    getDataPointStringList(key: string, locale: string = 'en'): string[] {
        let l: any[] = [];
        for (let dp of this.getDataPointsByKey(key)) {
            if (dp) {
                for (let dt of dp.data_types) {
                    if (dt['key'] == key) {
                        l.push(dp.value[locale]);
                    }
                }
            }
        }
        return l;
    }

    getDataPointsFirstString(key: string, locale: string = 'en') {
        let l = this.getDataPointStringList(key, locale);
        if (l.length > 0)
            return l[0];
        return '';
    }

    getDataPointsAsString(key: string, locale?: string, dataPointIdsToHighlight?: any) {
        // TODO dataPointIdsToHighlight is not implemented
        if (!locale)
            locale = 'en';

        let l = this.getDataPointStringList(key, locale);
        if (l.length > 0)
            return l.join(', ');
        return '';
    }

    getRdpBasicJobTitle() {
        return this.rdp_basic_job_title;
    }

    getRdpBasicCurrentLocation() {
        return this.rdp_basic_current_location;
    }

    getSkillsString() {
        return this.skills_string;
    }

    getCvFile() {
        return this.cv_file;
    }

    // 0-1 in percent
    getProfileCompleted() {
        return this.profile_completed * 100;
    }
}


export class AccountUserViewer extends AccountUserPublic implements Deserializable {
    updated: string;

    override deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getUpdated() {
        return this.updated;
    }
}


export class AccountUserProfile implements Deserializable {
    id: number;
    hash: string;
    cv: any;
    full_name: string;
    city: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getHash() {
        return this.hash;
    }

    getCV() {
        return this.cv;
    }

    getFullName() {
        return this.full_name;
    }

    getCity() {
        return this.city;
    }
}



export class AccountUserInvite implements Deserializable {
    id: number;
    account: number;
    country: number;

    role: string;
    form: string;

    email: string;

    seller: number;
    contract: number;
    employee: number;
    subcontractor: number;

    accepted: boolean;
    created;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getAccountId() {
        return this.account;
    }

    getCountryId() {
        return this.country;
    }

    getSellerId() {
        return this.seller;
    }

    getContractId() {
        return this.contract;
    }

    getEmployeeId() {
        return this.employee;
    }

    getSubcontractorId() {
        return this.subcontractor;
    }

    getRole() {
        return this.role;
    }

    getForm() {
        return this.form;
    }

    getEmail() {
        return this.email;
    }

    getCreated() {
        return this.created;
    }

}


export class UserSkillSet implements Deserializable {
    id: number;
    skill: number;
    skill_name: string;
    years_experience: number;
    level: string;

    // used for validation
    has_error_years_experience: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getSkillId() {
        return this.skill;
    }

    getSkillName() {
        return this.skill_name;
    }

    getYearsExperience() {
        return this.years_experience;
    }

    getLevel() {
        return this.level;
    }
}



export class UserExperience implements Deserializable {
    id: number;
    account_user: number;
    title: string;
    organization: string;
    description: string;
    location: string;
    form: string;
    type: string;
    start_year: number;
    start_month: number;
    end_year: number;
    end_month: number;
    present: boolean;

    hasFieldErrors: boolean = false;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getTitle() {
        return this.title;
    }

    getOrganization() {
        return this.organization;
    }

    getDescription() {
        return this.description;
    }

    getLocation() {
        return this.location;
    }

    getForm() {
        return this.form;
    }

    getType() {
        return this.type;
    }

    getStartYear() {
        return this.start_year;
    }

    getStartMonth() {
        return this.start_month;
    }

    getEndYear() {
        return this.end_year;
    }

    getEndMonth() {
        return this.end_month;
    }

    isPresent() {
        return this.present;
    }
}


