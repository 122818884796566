import { Deserializable } from "./deserializable";

export class Currency implements Deserializable {
    id: number;
    code: string;
    symbol: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getCode() {
        return this.code;
    }

    getSymbol() {
        return this.symbol;
    }
}

