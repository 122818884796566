import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, interval, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';

import { Capacitor } from '@capacitor/core';
import { Haptics } from '@capacitor/haptics';

import { environment } from '../../environments/environment';

import { Post, Hub, HubChannel, HubMember, HubTopic } from '../_models/network';
import { RelatedDataPoint } from '../_models/collection';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

    domain = environment.domain;
    prefix = environment.api_prefix;

    private unreadNotificationCountSubject = new BehaviorSubject<number | null>(null);
    unreadNotificationCount = this.unreadNotificationCountSubject.asObservable();

    constructor(private http: HttpClient) { }

    getFeedPosts(feedId: number, dpName: string, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-post/feed/?feed=' + feedId + '&dp=' + dpName + '&timestamp=' + timestamp + '&page=' + page;
        return this.http.get<any>(url);
    }

    getTrendingDataPointNames(): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-post/trending_topics/';
        return this.http.get<any>(url);
    }

    getPosts(auId: number, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-post/?user=' + auId + '&timestamp=' + timestamp + '&page=' + page;

        //return this.http.get<any>(url);
        return this.http.get<Post[]>(url).pipe(
            map(ps => ps
                .map(p => new Post().deserialize(p))
            )
        );
    }

    getComments(slug: string, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-post/?postComments=' + slug + '&timestamp=' + timestamp + '&page=' + page;

        return this.http.get<any>(url);
        /*return this.http.get<Post[]>(url).pipe(
            map(ps => ps
                .map(p => new Post().deserialize(p))
            )
        );*/
    }

    getPost(slug: string): Observable<Post> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-post/' + slug + '/' + '?timestamp=' + timestamp;

        return this.http.get<Post>(url).pipe(
            map(p => new Post().deserialize(p))
        );
    }

    getPostOgs(text: string): Observable<any> {
        let url = this.domain + this.prefix + '/network-post/ogs/';
        return this.http.post<any>(url, { text: text });
    }

    createPost(post: Post, rdps: RelatedDataPoint[], image1: any): Observable<Post> {
        let url = this.domain + this.prefix + '/network-post/';

        // combine the post and rdps into one object
        post.rdps = rdps;
        post.image1 = image1;

        return this.http.post<Post>(url, post).pipe(
            map(p => new Post().deserialize(p))
        );
    }

    updatePost(post: Post, rdps: RelatedDataPoint[]): Observable<Post> {
        let url = this.domain + this.prefix + '/network-post/' + post.slug + '/';

        // combine the post and rdps into one object
        post.rdps = rdps;

        return this.http.put<Post>(url, post).pipe(
            map(p => new Post().deserialize(p))
        );
    }

    deletePost(post: Post): Observable<Post> {
        let url = this.domain + this.prefix + '/network-post/' + post.slug + '/';

        return this.http.delete<Post>(url).pipe(
            map(p => new Post().deserialize(p))
        );
    }

    reportPost(post: Post, reportText: string): Observable<Post> {
        let url = this.domain + this.prefix + '/network-post/' + post.slug + '/report/';

        const data = {
            reason: reportText
        };

        return this.http.post<Post>(url, data).pipe(
            map(p => new Post().deserialize(p))
        );
    }

    endorseRequestPost(post: Post, email: string): Observable<Post> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-post/' + post.slug + '/endorse_request/' + '?timestamp=' + timestamp + '&email=' + email;

        return this.http.post<Post>(url, post).pipe(
            map(p => new Post().deserialize(p))
        );
    }

    // Like
    likePost(post: Post): Observable<Post> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-post/' + post.slug + '/like/' + '?timestamp=' + timestamp;

        return this.http.post<Post>(url, post).pipe(
            map(p => new Post().deserialize(p))
        );
    }


    getNotifications(auId: number, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-notification/?user=' + auId + '&timestamp=' + timestamp + '&page=' + page;
        return this.http.get<any>(url);
    }

    // get notification count every 1.5 min from server
    getNotificationUnreadCountIntervalServer(): Observable<any> {
        // check every 1.5 min
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-notification/unread_count/?timestamp=' + timestamp;
        return interval(1.5 * 60 * 1000).pipe(
            switchMap(() => this.http.post(url, {}))
        );
    }

    // get notification count from server once
    getNotificationUnreadCountServer(): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-notification/unread_count/?timestamp=' + timestamp;
        return this.http.post(url, {});
    }

    getNotificationUnreadCount(): Observable<number | null> {
        return this.unreadNotificationCount;
    }

    setNotificationUnreadCount(count: number) {
        this.unreadNotificationCountSubject.next(count);
    }

    // viewers

    getProfileViewers(accountUserId: number, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/account-user-public/' + accountUserId + '/list_viewers/'+ `?timestamp=${timestamp}` + '&page=' + page;
        return this.http.get<any>(url);
    }


    // connection

    getConnectionRequests(auId: number, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-connection/list_requests/?profile_id=' + auId + '&timestamp=' + timestamp + '&page=' + page;
        return this.http.get<any>(url);
    }

    getConnectionSuggestions(auId: number, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-connection/list_suggestions/?profile_id=' + auId + '&timestamp=' + timestamp + '&page=' + page;
        return this.http.get<any>(url);
    }

    getConnectedAccountUsers(auId: number, page: number = 1, auFilter: any, auSearch: string): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        //let url = this.domain + this.prefix + '/network-connection/list_connected_aus/?profile_id=' + auId + '&timestamp=' + timestamp + '&page=' + page + '&filter=' + auFilter.join(',');
        let url = this.domain + this.prefix + '/network-connection/list_connected_aus/?page=' + page;
        let data = {
            'profile_id': auId,
            'search': auSearch
        };
        for (let key in auFilter) {
            data["filter_" + key] = auFilter[key];
        }
        return this.http.post<any>(url, data);
    }

    createConnection(targetAuId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-connection/';
        return this.http.post<any>(url, { 'target_id': targetAuId });
    }

    acceptConnection(conId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-connection/' + conId + '/accept/';
        return this.http.post<Post>(url, {});
    }

    ignoreConnection(conId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-connection/' + conId + '/ignore/';
        return this.http.post<Post>(url, {});
    }

    // hub

    getHubs(selectedHubTopic: HubTopic | null | undefined, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-hub/?page=' + page + '&timestamp=' + timestamp;
        if (selectedHubTopic) {
            url += '&topic=' + selectedHubTopic.id;
        }
        if (selectedHubTopic === undefined) {
            url += '&for_you=true';
        }

        return this.http.get<any>(url);
    }

    getMyHubs(page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-hub/get_my_hubs/?page=' + page + '&timestamp=' + timestamp;
        return this.http.get<any>(url);
    }

    getHub(slug: string): Observable<Hub> {
        // timestamp is used to force refresh
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/network-hub/' + slug + '/' + '?timestamp=' + timestamp;
        return this.http.get<Hub>(url).pipe(
            map(h => new Hub().deserialize(h))
        );
    }

    createHub(hub: Hub): Observable<Hub> {
        let url = this.domain + this.prefix + '/network-hub/';
        return this.http.post<Hub>(url, hub);
    }

    updateHub(data: any): Observable<Hub> {
        let url = this.domain + this.prefix + '/network-hub/' + data.id + '/';
        return this.http.put<Hub>(url, data).pipe(
            map(h => new Hub().deserialize(h))
        );
    }

    deleteHub(hub: Hub): Observable<Hub> {
        let url = this.domain + this.prefix + '/network-hub/' + hub.id + '/';
        return this.http.delete<Hub>(url).pipe(
            map(h => new Hub().deserialize(h))
        );
    }

    getHubTopics(): Observable<HubTopic[]> {
        let url = this.domain + this.prefix + '/network-hub/get_topics/';
        return this.http.get<HubTopic[]>(url).pipe(
            map(hts => hts
                .map(ht => new HubTopic().deserialize(ht))
            )
        );
    }

    // hub members

    getHubMembers(hubId: number, page: number = 1): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hubId + '/get_members/?page=' + page;
        return this.http.post<any>(url, {});
    }

    // hub channel

    createHubChannel(channel: HubChannel): Observable<HubChannel> {
        let url = this.domain + this.prefix + '/network-hub/' + channel.getHubId() + '/create_channel/';
        return this.http.post<HubChannel>(url, channel);
    }

    updateHubChannel(channel: HubChannel): Observable<HubChannel> {
        let url = this.domain + this.prefix + '/network-hub/' + channel.getHubId() + '/update_channel/';
        return this.http.put<HubChannel>(url, channel).pipe(
            map(h => new HubChannel().deserialize(h))
        );
    }

    deleteHubChannel(channel: HubChannel): Observable<HubChannel> {
        let url = this.domain + this.prefix + '/network-hub/' + channel.getId() + '/delete_channel/';
        return this.http.delete<HubChannel>(url).pipe(
            map(h => new HubChannel().deserialize(h))
        );
    }

    sendHubInvite(hub: Hub, targetAuId: number, type: string): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hub.getId() + '/invite/';
        return this.http.post<any>(url, { 'target_id': targetAuId, 'member_type': type });
    }

    sendHubInviteViaEmail(hub: Hub, email: string, type: string): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hub.getId() + '/invite_via_email/';
        return this.http.post<any>(url, { 'email': email, 'member_type': type });
    }

    acceptHubInvite(inviteId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/accept_invite/';
        return this.http.post<any>(url, { hub_invite_id: inviteId });
    }

    ignoreHubInvite(inviteId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/ignore_invite/';
        return this.http.post<any>(url, { hub_invite_id: inviteId });
    }

    joinHub(hubId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hubId + '/join/';
        return this.http.post<any>(url, {});
    }

    leaveHub(hubId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hubId + '/leave/';
        return this.http.post<any>(url, {});
    }

    removeHubMember(hubId: number, memberId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hubId + '/remove_member/';
        return this.http.post<any>(url, { target_id: memberId });
    }

    banHubMember(hubId: number, memberId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hubId + '/ban_member/';
        return this.http.post<any>(url, { target_id: memberId });
    }

    addHubModerator(hubId: number, memberId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hubId + '/add_moderator/';
        return this.http.post<any>(url, { target_id: memberId });
    }

    removeHubModerator(hubId: number, memberId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hubId + '/remove_moderator/';
        return this.http.post<any>(url, { target_id: memberId });
    }

    addHubAdmin(hubId: number, memberId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hubId + '/add_admin/';
        return this.http.post<any>(url, { target_id: memberId });
    }

    removeHubAdmin(hubId: number, memberId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hubId + '/remove_admin/';
        return this.http.post<any>(url, { target_id: memberId });
    }

    acceptHubJoinRequest(hubId: number, memberId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hubId + '/accept_join_request/';
        return this.http.post<any>(url, { target_id: memberId });
    }

    declineHubJoinRequest(hubId: number, memberId: number): Observable<any> {
        let url = this.domain + this.prefix + '/network-hub/' + hubId + '/decline_join_request/';
        return this.http.post<any>(url, { target_id: memberId });
    }

}
