import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  showDesktop = signal<boolean>(true);
  showMobile = signal<boolean>(true);

  height: number = 0;

  constructor() { }

  getHeight() {
      return this.height;
  }

  setHeight(h: number) {
      this.height = h;
  }

}
